import * as types from '../actions/types';

const initialState = {};

const changePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_CHANGE_COST_PLAN_REQUESTED:
      return {
        ...state
      };
    case types.CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED:
      return {
        ...state
      };
    case types.CURRENT_USER_CHANGE_COST_PLAN_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default changePlanReducer;

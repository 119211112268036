import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { path } from 'ramda';
import Cookies from 'universal-cookie';
import { isMobile, isIOS } from 'react-device-detect';

import styles from './index.module.css';
import cancelIcon from '@assets/common/close-white-mode.png';
import appIcon from '@assets/DownloadAppBanner/app_icon.png';
import { requestStoreBannerFunctionData, getCurrentServerTime } from '@redux/actions';
import {
  APPSTORE_DOWNLOAD_LINK,
  GOOGLE_PLAY_DOWNLOAD_LINK,
  HIDE_APPSTORE_DOWNLOAD_BANNER_PERIOD, ResponseStatus
} from '@constants/constant';

const cookies = new Cookies();

class DownloadAppBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadAppBannerDisplayed: !cookies.get(
          'is_bachelor_appstore_download_banner_close'
      )
    };
  }

  handleStoreData() {
    const { userId } = this.props;
    const params = {
      user_id: userId,
      clicked_in: window.location.pathname.split('/')[1]
    };
    this.props.requestStoreBannerFunctionData(params);
  }

  closeDownloadAppBanner = () => {
    this.setState({ isDownloadAppBannerDisplayed: false });
    this.requestGetCurrentServerTimeAPI();
  };

  requestGetCurrentServerTimeAPI = () => {
    this.props.getCurrentServerTime({}, response => {
      if (response.status === ResponseStatus.SUCCESS) {
        const currentServerTime = new Date(response.data.data.current_server_time);
        const expireDate = new Date(
            currentServerTime.getTime() + HIDE_APPSTORE_DOWNLOAD_BANNER_PERIOD
        );
        cookies.set('is_bachelor_appstore_download_banner_close', true, {
          path: '/',
          expires: expireDate
        });
      }
    });
  };

  render() {
    const { registrationSteps, oneSignalPlayerId } = this.props;
    const { isDownloadAppBannerDisplayed } = this.state;
    return (
      registrationSteps === 16 &&
      !oneSignalPlayerId &&
      isMobile &&
      isDownloadAppBannerDisplayed && (
        <div className={styles.topBannerContainer}>
          <img
            alt=""
            src={cancelIcon}
            className={styles.bannerCloseButton}
            onClick={this.closeDownloadAppBanner}
          />
          <img alt="" src={appIcon} className={styles.appIcon} />
          <div className={styles.topBannerContent}>
            <div className={styles.topBannerNormalContent}>
              バチェラーデートアプリ登場！
            </div>
            <div className={styles.topBannerHighlightContent}>
              通知を見逃さずに、
            </div>
            <div className={styles.topBannerHighlightContent}>
              もっと便利にデートを。
            </div>
          </div>
          <div
            className={styles.downloadButton}
            onClick={() => {
              this.handleStoreData();
              window.open(isIOS ? APPSTORE_DOWNLOAD_LINK : GOOGLE_PLAY_DOWNLOAD_LINK);
            }}
          >
            <span className={styles.downloadButtonText}>ダウンロード</span>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    isWhiteMode: state.app.isWhiteMode,
    registrationSteps: path(
      ['userGlobalInfo', 'data', 'registration_steps'],
      state
    ),
    oneSignalPlayerId: path(['auth', 'userdata', 'userAuth', 'user', 'user_meta_data', 'one_signal_player_id'], state),
    userId: path(['auth', 'userdata', 'userAuth', 'user', 'id'], state)
  };
};

export default compose(
  connect(mapStateToProps, {
    requestStoreBannerFunctionData,
    getCurrentServerTime
  })
)(DownloadAppBanner);

import * as types from '../actions/types';
const INITIAL = {
  isError: false
};

const emailReservationReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.SHOW_ERROR:
      return {
        ...state,
        isError: true
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default emailReservationReducer;

import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { checkActiveSubscriptionPause } from '@utils';

function* getUserGlobalInfo(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_GLOBAL_USER, API.GET);
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_GLOBAL_INFO_SUCCEEDED,
        data: response.data
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({ type: types.GET_USER_GLOBAL_INFO_FAILD, error: response });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_USER_GLOBAL_INFO_FAILD, error });
  }
}
function* getCancelDeactiveOptions(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.GET_CANCEL_DEACTIVE_OPTIONS,
      API.GET
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CANCEL_DEACTIVE_APP_OPTIONS_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.GET_CANCEL_DEACTIVE_APP_OPTIONS_FAILED,
        error: response.response
      });
    } else {
      yield put({
        type: types.GET_CANCEL_DEACTIVE_APP_OPTIONS_FAILED,
        error: response.response
      });
    }
    if (action.onComplete) {
      action.onComplete(response.status ? response : response.response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_COMMIT_OPTION_FAILED, error });
  }
}

export function* watchGetUserGlobalInfo() {
  yield takeEvery(types.GET_USER_GLOBAL_INFO_REQUESTED, getUserGlobalInfo);
}

export function* watchGetCancelDeactiveOptions() {
  yield takeLatest(types.GET_CANCEL_DEACTIVE_APP_OPTIONS_REQUESTED, getCancelDeactiveOptions);
}
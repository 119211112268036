import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import Autolinker from 'autolinker';
import bachelorUser from '@assets/ChatFeature/bachelorUser.png';
import close from '@assets/common/close.png';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

//
// Shows partner's text bubble including chat messages from Bachelor. Appears to the left of screen.
// -----------------------
const PartnerTextMessage = ({
  avatar,
  message,
  shouldBlurIcon,
  createDate,
  createAt,
  isWhiteMode,
  handelClickGoToDetailPartner,
  type,
  url
}) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState('');

  useEffect(() => {
    url.then(res => setSrc(res));
  }, []);

  const handleOpenImage = () => {
    setOpen(true);
  };

  const handleCloseImage = () => {
    setOpen(false);
  };
  return (
    <span
      className={classNames(styles.partnerTextMessage, {
        [styles.partnerTextShowDate]: createDate
      })}
    >
      <div className={styles.partnerTextMessageGroup}>
        <img
          alt=""
          src={avatar ? avatar : bachelorUser}
          className={classNames({
            [styles.chatUserIcon]: true,
            [styles.blur]: shouldBlurIcon
          })}
          onClick={() => handelClickGoToDetailPartner()}
        />
        <div className={styles.chatPartnerTextBubbleGroup}>
          {type === 'media' ? (
            <div
              className={styles.chatPartnerImageWrapper}
              onClick={handleOpenImage}
            >
              <img src={src} alt="" className={styles.chatPartnerImage} />
            </div>
          ) : (
            <>
              <div
                className={classNames(styles.chatPartnerTextBubble, {
                  [styles.textBlack]: isWhiteMode
                })}
                dangerouslySetInnerHTML={{ __html: htmlMessage }}
              />
              <div className={styles.chatPartnerTextBubbleArrow}></div>
            </>
          )}
        </div>
      </div>
      {createDate ? (
        <div className={styles.chatPartnerCreateDate}>{createDate}</div>
      ) : null}
      {createAt ? (
        <span className={styles.chatPartnerCreateTime}>
          {createAt.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          })}
        </span>
      ) : null}
      {open && (
        <>
          <div className={styles.chatPartnerImagePreviewWrapper}>
            <div className={styles.chatPartnerImagePreview}>
              <img src={src} alt="img" className={styles.imagePreviewFull} />
            </div>
          </div>
          <img
            src={close}
            alt="close"
            onClick={handleCloseImage}
            className={styles.closeImage}
          />
        </>
      )}
    </span>
  );
};

PartnerTextMessage.propTypes = {
  isWhiteMode: PropTypes.bool,
  avatar: PropTypes.string,
  message: PropTypes.string,
  shouldBlurIcon: PropTypes.bool,
  createDate: PropTypes.any,
  createAt: PropTypes.any,
  type: PropTypes.string,
  url: PropTypes.string,
  handelClickGoToDetailPartner: PropTypes.func,
}

export default PartnerTextMessage;

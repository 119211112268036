import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const TitleComponent = ({ title }) => (
  <div className={styles.title}>{title}</div>
);
TitleComponent.propTypes = {
  title: PropTypes.string
}
export default TitleComponent;

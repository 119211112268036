import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* getUserNeedToUpdateNewPreferences(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_GET_NEED_UPDATE_NEW_PREFERENCES,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_FAILED,
      error
    });
  }
}

export function* watchGetUserNeedToUpdateNewPreferences() {
  yield takeLatest(
    types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_REQUESTED,
    getUserNeedToUpdateNewPreferences
  );
}

import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: null
};

const exceptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_EXCEPTION_NOTICE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.SEND_EXCEPTION_NOTICE_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        error: null
      };
    case types.SEND_EXCEPTION_NOTICE_FAILED:
      return {
        ...state,
        isError: true,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default exceptionReducer;

import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const TitleCustom = ({ message1 }) => (
  <div
    className={styles.titlePopUp}
    dangerouslySetInnerHTML={{ __html: message1 }}
  />
);
TitleCustom.propTypes = {
  message1: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}
export default TitleCustom;

export const matomoPageView = (userData, firstEvent = false) => {
  const matomo = window._matomo;
  if (matomo && matomo.trackPageView) {
    if (userData && userData.id && matomo.setUserId) {
      matomo.setUserId(userData.id);
    }
    // firstEvent to check pageview is called in `componentDidMount` or `componentDidUpdate`
    // firstEvent = true: componentDidMount (Matomo can catch url when first call pageview)
    // firstEvent = false: componentDidUpdate (set custom url for pageview because Matomo does not support well in Single Page Application)
    if (!firstEvent && matomo.setCustomUrl) {
      matomo.setCustomUrl(window.location.href);
    }
    matomo.trackPageView();
  }
};

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import classNames from 'classnames';

import bachelorUser from '@assets/ChatFeature/bachelorUser.png';
import { ReactComponent as CafeIcon } from '@assets/common/cafe.svg';
import PropTypes from 'prop-types';

class ParkSuggestionMessage extends Component {
  render() {
    const { t, parkSuggestion, isWhiteMode } = this.props;
    const { cafeList = [] } = parkSuggestion;

    return (
      <span className={styles.partnerTextMessage}>
        <div
          className={classNames(styles.chatPartnerTextBubble, {
            [styles.textBlack]: isWhiteMode
          })}
        >
          <div className={styles.chatPartnerTextHeader}>
            <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
            <div className={styles.ChatTextHeaderWrapper}>
              <h3 className={styles.text1}>{t('park-suggestion-text-1')}</h3>
              <h3 className={styles.text2}>{t('park-suggestion-text-2')}</h3>
            </div>
          </div>
          <div className={styles.text3Wrapper}>
            <CafeIcon className={styles.CafeIconSmall} />
            <span className={styles.text3}>{t('park-suggestion-text-3')}</span>
          </div>
          <div className={styles.CafeWrapper}>
            {cafeList.map((cafe, idx) => (
              <a
                key={idx}
                href={cafe.cafe_link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.CafeItem}
              >
                <img
                  src={cafe.image_url}
                  alt="cafe"
                  className={styles.CafeItemImg}
                />
                <div className={styles.CafeItemText}>
                  <h3 className={styles.CafeItemName}>{cafe.name}</h3>
                  <span className={styles.CafeItemStation}>
                    {cafe.station_name}
                  </span>
                </div>
                <div className={styles.CafeItemBlur}></div>
              </a>
            ))}
          </div>
        </div>
        <span className={styles.textNote}>
          {t('message-not-display-to-other-partner')}
        </span>
      </span>
    );
  }
}

ParkSuggestionMessage.propTypes = {
  parkSuggestion: PropTypes.object,
  isWhiteMode: PropTypes.bool
}

export default compose(withNamespaces('chat', { wait: true }))(
  ParkSuggestionMessage
);

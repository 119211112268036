import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* changeDatingStatus(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.CANCEL_DEACTIVATE_COMPLETE_DATING,
      API.POST
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_DATING_STATUS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.UPDATE_DATING_STATUS_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DATING_STATUS_FAILED, error });
  }
  store.dispatch(hideLoading());
  return response;
}

export function* watchChangeDatingStatus() {
  yield takeLatest(types.UPDATE_DATING_STATUS_REQUESTED, changeDatingStatus);
}

function* changeMultipleDatingStatus(action) {
  const datingIds = action.params;
  store.dispatch(showLoading());
  try {
    const firstRes = yield call(
      requestAPI,
      API.CANCEL_DEACTIVATE_COMPLETE_DATING + `/${datingIds[0]}`,
      API.PUT
    );

    const secondRes = yield call(
      requestAPI,
      API.CANCEL_DEACTIVATE_COMPLETE_DATING + `/${datingIds[1]}`,
      API.PUT
    );
    const responses = [firstRes, secondRes];

    const isSucceeded = responses.every(
      response => response.status === ResponseStatus.SUCCESS
    );
    const failResponse = responses.find(
      response => response.status !== ResponseStatus.SUCCESS
    );
    if (isSucceeded) {
      yield put({
        type: types.UPDATE_MULTIPLE_DATING_STATUS_SUCCEEDED,
        data: responses[0].data
      });
      action.onComplete(responses[0]);
    } else {
      yield put({
        type: types.UPDATE_MULTIPLE_DATING_STATUS_FAILED,
        error: failResponse
      });
      action.onComplete(failResponse);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MULTIPLE_DATING_STATUS_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchChangeMultipleDatingStatus() {
  yield takeLatest(
    types.UPDATE_MULTIPLE_DATING_STATUS_REQUESTED,
    changeMultipleDatingStatus
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* chatHistory(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.SAVE_CHAT_HISTORY,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHAT_HISTORY_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CHAT_HISTORY_REQUEST_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHAT_HISTORY_REQUEST_FAILED, error });
  }
}

function* updateCursor(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.UPDATE_CURSOR,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_CURSOR_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.UPDATE_CURSOR_REQUEST_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CURSOR_REQUEST_FAILED, error });
  }
}

export function* watchChatHistory() {
  yield takeLatest(types.CHAT_HISTORY_REQUESTED, chatHistory);
}

export function* watchUpdateCursor() {
  yield takeLatest(types.UPDATE_CURSOR_REQUESTED, updateCursor);
}

import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: {}
};

const feedbackCommitOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_FEEDBACK_COMMIT_OPTION_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.SAVE_USER_FEEDBACK_COMMIT_OPTION_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.SAVE_USER_FEEDBACK_COMMIT_OPTION_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default feedbackCommitOptionReducer;

import * as types from '../actions/types';

const initialState = {
  data: '',
  isError: false
};

const updateEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_EMAIL_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_EMAIL_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        error: null
      };
    case types.UPDATE_EMAIL_FAILED:
      return {
        ...state,
        isError: true,
        data: null,
        error: action.error
      };
    case types.STOP_RECEIVE_NOTIFICATIONS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.STOP_RECEIVE_NOTIFICATIONS_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.STOP_RECEIVE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isError: true,
        data: null,
        error: action.error
      };
    case types.GET_STOP_NOTIFICATION_CATEGORIES_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_STOP_NOTIFICATION_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.GET_STOP_NOTIFICATION_CATEGORIES_FAILED:
      return {
        ...state,
        isError: true,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default updateEmailReducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '@redux/actions/types';
import { requestAPI } from '@redux/services/request';
import { API } from '@redux/services/api';
import { ResponseStatus } from '@constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getEvaluationInfoData(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.DATING_REPORT,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.EVALUATION_INFO_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({ type: types.EVALUATION_INFO_FAILED, error: response });
    } else {
      yield put({ type: types.EVALUATION_INFO_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.EVALUATION_INFO_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetEvaluationInfoData() {
  yield takeLatest(types.EVALUATION_INFO_REQUEST, getEvaluationInfoData);
}

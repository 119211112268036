export const popups = [
  {
    title: 'POPUP 1',
    message: 'MESSAGE WITH BREAKS \n\n THIS IS BELOW THE FIRST MESSAGE ',
    id: 'abcd1',
    buttons: [{ action: 'ok', sort_id: 0, text: 'Ok' }]
  },
  {
    title: 'POPUP 2',
    message: 'MESSAGE 2 WITH BREAKS \n\n THIS IS BELOW THE FIRST MESSAGE ',
    id: 'abcd2',
    buttons: [{ action: 'ok', sort_id: 0, text: 'Ok' }]
  }
];

export const matchDataMock = {
  message: '1',
  data: {
    weeks: [
      [
        {
          matching_day: 'wednesday',
          priorityPreference: {
            first_match: "She doesn't smoke.",
            second_match: "Alcohol Prefrence is 'Sometimes'.",
            third_match: 'api_messages.female_marriage_intention_preference_is'
          },
          partner_details: {
            id: 2,
            name: 'Ms',
            gender: 2,
            email: 'mark.zulauf@example.org',
            mobile_number: '4630321161',
            status: 4,
            registration_steps: 6,
            prefecture_id: 1,
            support_tracking_url: null,
            team_member_rate: 3,
            flex_point: 0,
            is_fake: 0,
            has_success_date: 0,
            invitation_code: 'qLqFSXNl',
            invitation_url: null,
            user_profile: {
              id: 40,
              user_id: 2,
              birthday: '1999-03-30',
              height: 164,
              body_type: {
                displayName: 'Skinny',
                value: 1
              },
              marriage_intention: {
                displayName: 'As Soon As Possible',
                value: 1
              },
              character: {
                displayName: 'Happy, Sincere, Helpful, Caring, Independent',
                value: '3,5,7,10,19'
              },
              smoking: {
                displayName: 'Non Smoker',
                value: 1
              },
              drinking: {
                displayName: 'Sometimes',
                value: 3
              },
              divorce: {
                displayName: 'No history of divorce',
                value: '1'
              },
              annual_income: {
                displayName: '4 ~ 6 Million Yen',
                value: 2
              },
              appearance_strength: {
                displayName:
                  'Beautiful Eyes, Beautiful Nose, Sexy, Voluptuous / Hunky',
                value: 'st_02,st_03,st_08,st_10'
              },
              appearance_features: {
                displayName:
                  'Double Eyelids, Long Hair, White Skin, Thick Eyebrow',
                value: 'fe_01,fe_03,fe_09,fe_07'
              },
              education: {
                displayName: 'Associate / Diploma',
                value: '3'
              },
              school_name: '海外大学',
              company_name: '12d',
              job: {
                displayName: 'Student',
                value: 45
              },
              hobby: {
                displayName:
                  'Fashion, Photography / Camera, Movie / Drama, Cuisine, Sweets, Gourmet · Walking Eat, Karaoke, Dart Billiards, Car Drive, Travel · Hot Spring, Overseas, Sake, Cafe Visit, Outdoor, Interior · DIY',
                value: '1,3,5,7,8,9,10,11,14,17,18,19,21,23,25'
              },
              education_group: {
                displayName: 'Associate / Diploma',
                value: 2
              },
              school_code: 'SC000073'
            },
            user_preference: {
              id: 4,
              user_id: 2,
              age_from: 20,
              age_to: 31,
              height_to: 181,
              height_from: 149,
              partner_body_min: {
                displayName: 'Skinny Fit',
                value: 1
              },
              partner_body_max: {
                displayName: '',
                value: 4
              },
              smoking: {
                displayName: 'Electrical Smoker Is OK',
                value: 1
              },
              drinking: {
                displayName: "I Don't Mind",
                value: 0
              },
              divorce: {
                displayName: 'No history of divorce/No kids',
                value: 1
              },
              annual_income: {
                displayName: '',
                value: 1
              },
              education: {
                displayName: 'Prefer More Than Uni',
                value: 1
              },
              job: {
                displayName: 'Doctor / Dentist',
                value: '1'
              },
              face_preferences: {
                displayName: '',
                value: '1'
              },
              appearance_priority: {
                displayName: 'Personality Is Much More Important',
                value: 0
              },
              first_priority: {
                displayName: 'Height',
                value: 1
              },
              second_priority: {
                displayName: 'Marriage Intention',
                value: 8
              },
              third_priority: {
                displayName: 'Body Shape',
                value: 3
              },
              hobby: {
                displayName: 'Fashion',
                value: 1
              },
              character: {
                displayName: null,
                value: null
              }
            }
          },
          dating_id: 'UzdiM2FtSWtzbDhmc3poVW1SSkNOQT09',
          dating_place: {
            id: 1,
            area_id: 1,
            category: 'cafe',
            latitude: 0,
            longitude: 0,
            rating: 0,
            phone: '0984343433',
            status: 1,
            dating_place_translations: [
              {
                id: 1,
                dating_place_id: 1,
                language_id: 1,
                name: 'ロジェカフェ（恵比寿)',
                display_address: 'Test',
                city: 'ads',
                zip_code: 'adsds',
                country: 'Japan'
              }
            ],
            dating_place_images: [
              {
                id: 1,
                dating_place_id: 1,
                image: 'test.jpg',
                status: 1
              },
              {
                id: 2,
                dating_place_id: 1,
                image: 'test1.jpg',
                status: 1
              }
            ],
            train_station: []
          },
          dating_status: 1,
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '2021-02-20 17:00:00',
          cancel_button: true,
          day: 'Wednesday',
          date: '02/03',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        },
        {
          chat_id: 1,
          dating_status: 1,
          dating_id: 'UzdiM2FtSWtzbDhmc3poVW1SSkNOQO99',
          matching_day: 'saturday',
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Saturday',
          date: '02/06',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3,
          requested_12pm_rematching: true,
          before_12pm_rematching: false,
          is_after_match_profile_allow_origin: true,
          partner_cancelled: true
        },
        {
          matching_day: 'sunday',
          chat_id: 1,
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Sunday',
          date: '02/07',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        }
      ],
      [
        {
          matching_day: 'wednesday',
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Wednesday',
          date: '02/03',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        },
        {
          matching_day: 'saturday',
          chat_id: 1,
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Saturday',
          date: '02/06',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        },
        {
          chat_id: 1,
          matching_day: 'sunday',
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Sunday',
          date: '02/07',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        }
      ],
      [
        {
          matching_day: 'wednesday',
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Wednesday',
          date: '02/03',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        },
        {
          matching_day: 'saturday',
          chat_id: 1,
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Saturday',
          date: '02/06',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        },
        {
          matching_day: 'sunday',
          chat_id: 1,
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          cancel_button: false,
          day: 'Sunday',
          date: '02/07',
          subscription_status: 'trial',
          user_plan_type: '',
          bachelor_rate: 3
        }
      ]
    ],
    preferences_matched_with: 'api_messages.match_point_with_Male',
    current_server_time: '2021-02-08T17:36:40.921241Z',
    // second_match_time: '2021-02-20 17:00:00',
    // third_match_time: '2021-02-20 17:00:00',
    user_has_card: true,
    //Confirm
    charge: {
      before_dating_date: 2000,
      on_dating_date: 3000,
      no_shown: 6000,
      currency: '円'
    }
  }
};

export const matchDetail = {
  message: 'Successful',
  data: {
    partner_detail: {
      id: 2,
      gender: 2,
      age: 31,
      job: '\u5f79\u54e1',
      income: '400\u4e07\u5186~600\u4e07\u5186',
      graduation: 'Entertainment / Culture',
      height: 162,
      body_shape: '\u666e\u901a',
      appearance_strength: ['\u76ee\u304c\u3071\u3063\u3061\u308a'],
      hobby: ['\u97f3\u697d\u30fb\u697d\u5668'],
      personality: [
        '\u6c17\u524d\u304c\u3044\u3044',
        '\u4ed5\u4e8b\u597d\u304d'
      ],
      bachelor_rate: 3,
      show_crown: true,
      priorityPreference: {
        first: '',
        second: '',
        third:
          '\u8eab\u9577\u304c\u3001\u300c162 cm\u300d\u306e\u65b9\u3067\u3059\u3002'
      }
    },
    dating_place: {
      time: '17:00',
      day: 'Wednesday',
      date: '05/26',
      phone: '03-5345-5345',
      name: 'CAFE PARK \u6075\u6bd4\u5bff\u5e97',
      nearest_station: '\u6075\u6bd4\u5bff\u99c5',
      address: '\u6e0b\u8c37\u533a\u6075\u6bd4\u5bff36-19',
      rating: 5,
      image: 'http=> //www.outdoor-photos.com/_photo/1617105.jpg',
      latitude: 0.093232,
      longitude: 0.983244
    },
    current_server_time: '2021-05-28T07:28:05.351873Z',
    charge: {
      before_dating_date: 2000,
      on_dating_date: 3000,
      no_shown: 6000,
      currency: '\u5186'
    }
  }
};

export const detailPartnerData = {
  data: {
    id: 2,
    gender: 2,
    status: 4,
    cost_plan: 'premium',
    age: '27',
    job: '経営者',
    income: '1000万~1500万',
    graduation: '慶應義塾大学',
    height: '155cm',
    body_shape: '普通',
    appearance_strength: '歯並び、肌、笑顔',
    hobby: '旅行、買い物、散歩、 カフェ巡り',
    personality: '優しい、明るい、寛容、決断力がある',
    user_rating: '3.0',
    priorityPreference: {
      first_match: '年齢が’27歳’の方です。',
      second_match: '顔のタイプが’目がぱっちりで、小顔’の方です。',
      third_match: '離婚歴が、’ない’の方です。'
    },
    dating_place: {
      time: '16:00',
      day: 'Sunday',
      date: '5/17',
      phone: '03-5345-5345',
      name: 'CAFE PARK 恵比寿店',
      nearest_station: '恵比寿駅',
      address: '渋谷区恵比寿36-19',
      rating: 5,
      image: 'http://www.outdoor-photos.com/_photo/1617105.jpg',
      latitude: 0.093232,
      longitude: 0.983244
    }
  }
};

export const matchDataMock2 = {
  message: '1',
  data: {
    weeks: [
      [
        {
          matching_day: 'wednesday',
          partner_details: {
            id: 2,
            gender: 2,
            status: 4,
            cost_plan: 'premium',
            age: '27',
            job: '経営者',
            user_rating: 3
          },
          dating_id: 'UzdiM2FtSWtzbDhmc3poVW1SSkNOQT09',
          dating_status: 1,
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '2021-02-20 17:00:00',
          unread_count: 1,
          day: 'Wednesday',
          date: '02/03',
          time: '17:00'
        },
        {
          chat_id: 1,
          dating_status: 3,
          dating_id: 'UzdiM2FtSWtzbDhmc3poVW1SSkNOQO99',
          matching_day: 'saturday',
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          day: 'Saturday',
          date: '02/06',
          requested_12pm_rematching: true,
          before_12pm_rematching: false,
          is_after_match_profile_allow_origin: true,
          partner_cancelled: true,
          time: '夕方'
        },
        {
          matching_day: 'sunday',
          chat_id: 1,
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          day: 'Sunday',
          date: '02/07'
        }
      ],
      [
        {
          matching_day: 'wednesday',
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '',
          day: 'Wednesday',
          date: '02/03'
        },
        {
          matching_day: 'saturday',
          chat_id: 1,
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          day: 'Saturday',
          date: '02/06'
        },
        {
          chat_id: 1,
          matching_day: 'sunday',
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          day: 'Sunday',
          date: '02/07'
        }
      ],
      [
        {
          matching_day: 'wednesday',
          chat_id: 1,
          chat_start_time: '2021-02-02 17:00:00',
          chat_end_time: '',
          day: 'Wednesday',
          date: '02/03'
        },
        {
          matching_day: 'saturday',
          chat_id: 1,
          chat_start_time: '2021-02-05 17:00:00',
          chat_end_time: '',
          day: 'Saturday',
          date: '02/06'
        },
        {
          matching_day: 'sunday',
          chat_id: 1,
          chat_start_time: '2021-02-06 17:00:00',
          chat_end_time: '',
          day: 'Sunday',
          date: '02/07'
        }
      ]
    ],
    current_server_time: '2021-02-08T17:36:40.921241Z',
    user_has_card: true,
    charge: {
      before_dating_date: 2000,
      on_dating_date: 3000,
      no_shown: 6000,
      currency: '円'
    }
  }
};

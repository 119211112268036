import React, { Component } from 'react';
import DefaultPopup from '../DefaultPopup';
import PropTypes from 'prop-types';

class ChangePlanSucess extends Component {
  render() {
    const { popup } = this.props;

    return (
      <DefaultPopup
        popup={popup}
        onButtonClick={this.props.onButtonClick}
        onCloseClick={this.props.onCloseClick}
      />
    );
  }
}

ChangePlanSucess.propTypes = {
  popup: PropTypes.any,
  onButtonClick: PropTypes.func,
  onCloseClick: PropTypes.func
}

export default ChangePlanSucess;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* updateUserMotivatedDating(action) {
  const showGeneralPopup = false;
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_UPDATE_SEEN_MOTIVATED_DATING,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_USER_MOTIVATED_DATING_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.UPDATE_USER_MOTIVATED_DATING_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_MOTIVATED_DATING_FAILED, error });
  }
}

export function* watchUpdateUserMotivatedDating() {
  yield takeLatest(
    types.UPDATE_USER_MOTIVATED_DATING_REQUESTED,
    updateUserMotivatedDating
  );
}

import React, { Component } from 'react';
import styles from './index.module.css';

import PartnerTextMessage from '@components/ChatFeature/PartnerTextMessage';
import UserTextMessage from '@components/ChatFeature/UserTextMessage';
import CafeInformationMessage from '@components/ChatFeature/CafeInformationMessage';
import CafeSuggestionMessage from '@components/ChatFeature/CafeSuggestionMessage';
import ParkSuggestionMessage from '@components/ChatFeature/ParkSuggestionMessage';
import VideoSuggestionMessage from '@components/ChatFeature/VideoSuggestionMessage';
import { ChatFeature as ChatFeatureConstants } from '@constants/constant';
import { ThreeDots } from '@components/Loading';
import ChangeTimeSuggestionMessage from '../ChangeTimeSuggestionMessage';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const {
  USER_TYPE_BASE_USER,
  USER_TYPE_PARTNER_USER,
  USER_TYPE_CAFE_INFO,
  USER_TYPE_CAFE_SUGGESTION,
  USER_TYPE_PARK_SUGGESTION,
  USER_TYPE_VIDEO_SUGGESTION,
  USER_TYPE_CHANGE_TIME_SUGGESTION
} = ChatFeatureConstants;
//
// Shows the interaction of a chat room session.
// -----------------------
class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.scrollToRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToLatestMessage();
  }

  componentDidUpdate() {
    if (this.props.shouldScrollToLatestMessage) {
      this.scrollToLatestMessage();
    }
  }

  scrollToLatestMessage() {
    // iosキーボードが表示されてからスクロールする
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1) {
      setTimeout(() => {
        this.scrollToRef.current.scrollIntoView();
      }, 300);
    } else {
      setTimeout(() => {
        this.scrollToRef.current.scrollIntoView();
      }, 500);
    }
  }

  handleOnScroll = event => {
    if (event.target.scrollTop === 0) {
      this.props.fetchMoreMessages();
    }
  };

  render() {
    const {
      chatRoomIsReady,
      messages,
      partnerData,
      handelClickGoToDetailPartner,
      isWhiteMode
    } = this.props;

    return (
      <div
        className={classNames(styles.chatRoom)}
        onScroll={this.handleOnScroll}
      >
        {messages.map((message, idx) => {
          if (!message) {
            return null;
          }
          switch (message.userType) {
            case USER_TYPE_BASE_USER:
              return (
                <UserTextMessage
                  isWhiteMode={isWhiteMode}
                  key={message.id}
                  message={message.text}
                  read={message.read}
                  onUnreadClick={message.onUnreadClick}
                  createDate={message.createDate}
                  createAt={message.createAt}
                  type={message.type}
                  url={message.url}
                />
              );
            case USER_TYPE_PARTNER_USER:
              return (
                <PartnerTextMessage
                  isWhiteMode={isWhiteMode}
                  key={message.id}
                  avatar={message.avatar}
                  message={message.text}
                  shouldBlurIcon={false}
                  createDate={message.createDate}
                  createAt={message.createAt}
                  type={message.type}
                  url={message.url}
                  handelClickGoToDetailPartner={handelClickGoToDetailPartner}
                />
              );
            case USER_TYPE_CAFE_INFO: {
              const cafeInfo = message.data;
              return (
                <CafeInformationMessage
                  key={idx}
                  cafeInfo={cafeInfo}
                  partnerData={partnerData}
                  isWhiteMode={isWhiteMode}
                />
              );
            }
            case USER_TYPE_CAFE_SUGGESTION: {
              const cafeSuggestion = message.data;
              return (
                <CafeSuggestionMessage
                  key={idx}
                  isWhiteMode={isWhiteMode}
                  cafeSuggestion={cafeSuggestion}
                />
              );
            }
            case USER_TYPE_PARK_SUGGESTION: {
              const parkSuggestion = message.data;
              return (
                <ParkSuggestionMessage
                  key={'ParkSuggestionMessage_' + idx}
                  isWhiteMode={isWhiteMode}
                  parkSuggestion={parkSuggestion}
                />
              );
            }
            case USER_TYPE_VIDEO_SUGGESTION: {
              const { onClickVideoMessage } = message.data;
              return (
                <VideoSuggestionMessage
                  key={'VideoSuggestionMessage_' + idx}
                  isWhiteMode={isWhiteMode}
                  onClickVideoMessage={onClickVideoMessage}
                />
              );
            }
            case USER_TYPE_CHANGE_TIME_SUGGESTION: {
              const { onClickChangeTimeMessage } = message.data;
              return (
                <ChangeTimeSuggestionMessage
                  key={'VideoSuggestionMessage_' + idx}
                  isWhiteMode={isWhiteMode}
                  onClickChangeTimeMessage={onClickChangeTimeMessage}
                />
              );
            }
            default:
              return null;
          }
        })}
        {!chatRoomIsReady && (
          <div className={styles.loadingMessageContainer}>
            <ThreeDots />
          </div>
        )}
        <span key="scroll" className={styles.scrollTo} ref={this.scrollToRef} />
      </div>
    );
  }
}

ChatRoom.propTypes = {
  chatRoomIsReady: PropTypes.bool,
  messages: PropTypes.array,
  partnerData: PropTypes.object,
  openGroupBtn: PropTypes.bool,
  handelClickGoToDetailPartner: PropTypes.func,
  fetchMoreMessages: PropTypes.func,
  shouldScrollToLatestMessage: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isWhiteMode: state.app.isWhiteMode
  };
};

export default connect(mapStateToProps, {})(ChatRoom);

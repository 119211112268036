import React from 'react';
import { Drawer } from 'antd-mobile';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class DrawerMenu extends React.Component {
  render() {

    return (
      <div style={{ height: '100%', zIndex: 9999999,
        position: 'relative'
      }}>
        <Drawer
          className="my-drawer"
          contentStyle={{
            height: '100%'
          }}
        >
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

Drawer.propTypes = {
  children: PropTypes.element
}

export default compose(
  withNamespaces('drawer', { wait: true }),
  withRouter,
)(DrawerMenu);

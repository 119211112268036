export const CURRENT_USER_LOGIN_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_FAILED =
  'CURRENT_USER_LOGIN_FACEBOOK_FAILED';
export const CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGOUT_FACEBOOK';
export const CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGOUT_FACEBOOK_FAILED =
  'CURRENT_USER_LOGOUT_FACEBOOK_FAILED';
export const CURRENT_USER_SUCCESS_NOT_401 = 'CURRENT_USER_SUCCESS_NOT_401';

export const ADMIN_FORCE_LOGIN_REQUESTED = 'ADMIN_FORCE_LOGIN_REQUESTED';
export const ADMIN_FORCE_LOGIN_SUCCEEDED = 'ADMIN_FORCE_LOGIN_SUCCEEDED';
export const ADMIN_FORCE_LOGIN_FAILED = 'ADMIN_FORCE_LOGIN_FAILED';

//SMS login
export const SMS_LOGIN_SEND_CODE_REQUESTED = 'SMS_LOGIN_SEND_CODE_REQUESTED';
export const SMS_LOGIN_SEND_CODE_SUCCEEDED = 'SMS_LOGIN_SEND_CODE_SUCCEEDED';
export const SMS_LOGIN_SEND_CODE_FAILED = 'SMS_LOGIN_SEND_CODE_FAILED';
export const SMS_LOGIN_VERIFICATION_CODE_REQUESTED =
  'SMS_LOGIN_VERIFICATION_CODE_REQUESTED';
export const SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED =
  'SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED';
export const SMS_LOGIN_VERIFICATION_CODE_FAILED =
  'SMS_LOGIN_VERIFICATION_CODE_FAILED';

export const HOME_SCREEN_REQUESTED = 'HOME_SCREEN_REQUESTED';
export const HOME_SCREEN_SUCCEEDED = 'HOME_SCREEN_SUCCEEDED';
export const HOME_SCREEN_FAILED = 'HOME_SCREEN_FAILED';
export const CLEAR_HOMESCREEN = 'CLEAR_HOMESCREEN';

export const CURRENT_USER_RESET_MATCH_DATA = 'CURRENT_USER_RESET_MATCH_DATA';
export const CURRENT_USER_GET_MATCH_DATA_REQUESTED =
  'CURRENT_USER_GET_MATCH_DATA_REQUESTED';
export const CURRENT_USER_GET_MATCH_DATA_SUCCEEDED =
  'CURRENT_USER_GET_MATCH_DATA_SUCCEEDED';
export const CURRENT_USER_GET_MATCH_DATA_FAILED =
  'CURRENT_USER_GET_MATCH_DATA_FAILED';
export const PARTNER_REFLECTED_COUPON_POPUP_SHOWN =
  'PARTNER_REFLECTED_COUPON_POPUP_SHOWN';
export const UPDATE_USER_PARTICIPATION_DATES =
  'UPDATE_USER_PARTICIPATION_DATES';

export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED';
export const USER_PROFILE_SUCCEEDED = 'USER_PROFILE_SUCCEEDED';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';

export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCEEDED = 'UPDATE_PROFILE_SUCCEEDED';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const GET_PREFERENCES_INFO_REQUESTED = 'GET_PREFERENCES_INFO_REQUESTED';
export const GET_PREFERENCES_INFO_SUCCEEDED = 'GET_PREFERENCES_INFO_SUCCEEDED';
export const GET_PREFERENCES_INFO_FAILED = 'GET_PREFERENCES_INFO_FAILED';

export const UPDATE_PREFERENCES_REQUESTED = 'UPDATE_PREFERENCES_REQUESTED';
export const UPDATE_PREFERENCES_SUCCEEDED = 'UPDATE_PREFERENCES_SUCCEEDED';
export const UPDATE_PREFERENCES_FAILED = 'UPDATE_PREFERENCES_FAILED';

export const USE_COUPON_REQUESTED = 'USE_COUPON_REQUESTED';
export const USE_COUPON_SUCCEEDED = 'USE_COUPON_SUCCEEDED';
export const USE_COUPON_FAILED = 'USE_COUPON_FAILED';

export const RETRIEVE_COUPON_REQUESTED = 'RETRIEVE_COUPON_REQUESTED';
export const RETRIEVE_COUPON_SUCCEEDED = 'RETRIEVE_COUPON_SUCCEEDED';
export const RETRIEVE_COUPON_FAILED = 'RETRIEVE_COUPON_FAILED';

export const PURCHASE_COUPON_REQUESTED = 'PURCHASE_COUPON_REQUESTED';
export const PURCHASE_COUPON_SUCCEEDED = 'PURCHASE_COUPON_SUCCEEDED';
export const PURCHASE_COUPON_FAILED = 'PURCHASE_COUPON_FAILED';

export const GET_CANCEL_DATING_OPTIONS_REQUESTED =
  'GET_CANCEL_DATING_OPTIONS_REQUESTED';
export const GET_CANCEL_DATING_OPTIONS_SUCCEEDED =
  'GET_CANCEL_DATING_OPTIONS_SUCCEEDED';
export const GET_CANCEL_DATING_OPTIONS_FAILED =
  'GET_CANCEL_DATING_OPTIONS_FAILED';
export const CANCEL_DATE_REQUESTED = 'CANCEL_DATE_REQUESTED';
export const CANCEL_DATE_SUCCEEDED = 'CANCEL_DATE_SUCCEEDED';
export const CANCEL_DATE_FAILED = 'CANCEL_DATE_FAILED';
export const CANCEL_FOR_REMATCH_REQUESTED = 'CANCEL_FOR_REMATCH_REQUESTED';
export const CANCEL_FOR_REMATCH_SUCCEEDED = 'CANCEL_FOR_REMATCH_SUCCEEDED';
export const CANCEL_FOR_REMATCH_FAILED = 'CANCEL_FOR_REMATCH_FAILED';

export const CANCEL_DATE_BY_PARTNER_REQUESTED =
  'CANCEL_DATE_BY_PARTNER_REQUESTED';
export const CANCEL_DATE_BY_PARTNER_SUCCEEDED =
  'CANCEL_DATE_BY_PARTNER_SUCCEEDED';
export const CANCEL_DATE_BY_PARTNER_FAILED = 'CANCEL_DATE_BY_PARTNER_FAILED';

export const PARTICIPATE_REMATCH_REQUESTED = 'PARTICIPATE_REMATCH_REQUESTED';
export const PARTICIPATE_REMATCH_SUCCEEDED = 'PARTICIPATE_REMATCH_SUCCEEDED';
export const PARTICIPATE_REMATCH_FAILED = 'PARTICIPATE_REMATCH_FAILED';

export const POPUPS_REQUESTED = 'POPUPS_REQUESTED';
export const POPUPS_REQUEST_SUCCEEDED = 'POPUPS_REQUEST_SUCCEEDED';
export const POPUPS_REQUEST_FAILED = 'POPUPS_REQUEST_FAILED';

export const DELETE_POPUP_REQUESTED = 'DELETE_POPUP_REQUESTED';
export const DELETE_POPUP_REQUEST_SUCCEEDED = 'DELETE_POPUP_REQUEST_SUCCEEDED';
export const DELETE_POPUP_REQUEST_FAILED = 'DELETE_POPUP_REQUEST_FAILED';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const CHAT_ROOM_REQUESTED = 'CHAT_ROOM_REQUESTED';
export const CHAT_ROOM_REQUEST_SUCCEEDED = 'CHAT_ROOM_REQUEST_SUCCEEDED';
export const CHAT_ROOM_REQUEST_FAILED = 'CHAT_ROOM_REQUEST_FAILED';

export const CHAT_HISTORY_REQUESTED = 'CHAT_HISTORY_REQUESTED';
export const CHAT_HISTORY_REQUEST_SUCCEEDED = 'CHAT_HISTORY_REQUEST_SUCCEEDED';
export const CHAT_HISTORY_REQUEST_FAILED = 'CHAT_HISTORY_REQUEST_FAILED';

export const UPDATE_CURSOR_REQUESTED = 'UPDATE_CURSOR_REQUESTED';
export const UPDATE_CURSOR_REQUEST_SUCCEEDED = 'UPDATE_CURSOR_REQUEST_SUCCEEDED';
export const UPDATE_CURSOR_REQUEST_FAILED = 'UPDATE_CURSOR_REQUEST_FAILED';

export const GET_CAFE_REQUESTED = 'GET_CAFE_REQUESTED';
export const GET_CAFE_REQUEST_SUCCEEDED = 'GET_CAFE_REQUEST_SUCCEEDED';
export const GET_CAFE_REQUEST_FAILED = 'GET_CAFE_REQUEST_FAILED';

export const GET_CHAT_MESSAGES_REQUESTED = 'GET_CHAT_MESSAGES_REQUESTED';
export const GET_CHAT_MESSAGES_REQUEST_SUCCEEDED =
  'GET_CHAT_MESSAGES_REQUEST_SUCCEEDED';
export const GET_CHAT_MESSAGES_REQUEST_FAILED =
  'GET_CHAT_MESSAGES_REQUEST_FAILED';
export const CLEAR_CHAT_MESSAGES = 'CLEAR_CHAT_MESSAGES';

export const GET_TWILIO_TOKEN_REQUESTED = 'GET_TWILIO_TOKEN_REQUESTED';
export const GET_TWILIO_TOKEN_SUCCEEDED = 'GET_TWILIO_TOKEN_SUCCEEDED';
export const GET_TWILIO_TOKEN_FAILED = 'GET_TWILIO_TOKEN_FAILED';

export const SET_PARTICIPATION_REQUESTED = 'SET_PARTICIPATION_REQUESTED';
export const SET_PARTICIPATION_SUCCEEDED = 'SET_PARTICIPATION_SUCCEEDED';
export const SET_PARTICIPATION_FAILED = 'SET_PARTICIPATION_FAILED';

export const CANCEL_PARTICIPATE_REQUESTED = 'CANCEL_PARTICIPATE_REQUESTED';
export const CANCEL_PARTICIPATE_SUCCEEDED = 'CANCEL_PARTICIPATE_SUCCEEDED';
export const CANCEL_PARTICIPATE_FAILED = 'CANCEL_PARTICIPATE_FAILED';
export const GET_MOTIVATE_IMAGES_REQUESTED = 'GET_MOTIVATE_IMAGES_REQUESTED';
export const GET_MOTIVATE_IMAGES_SUCCEEDED = 'GET_MOTIVATE_IMAGES_SUCCEEDED';
export const GET_MOTIVATE_IMAGES_FAILED = 'GET_MOTIVATE_IMAGES_FAILED';
export const CANCEL_SAMPLE_PARTICIPANT_REQUESTED =
  'CANCEL_SAMPLE_PARTICIPANT_REQUESTED';
export const CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED =
  'CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED';
export const CANCEL_SAMPLE_PARTICIPANT_FAILED =
  'CANCEL_SAMPLE_PARTICIPANT_FAILED';

export const GET_CANCEL_SAMPLE_DATING_OPTIONS_REQUESTED =
  'GET_CANCEL_SAMPLE_DATING_OPTIONS_REQUESTED';
export const GET_CANCEL_SAMPLE_DATING_OPTIONS_SUCCEEDED =
  'GET_CANCEL_SAMPLE_DATING_OPTIONS_SUCCEEDED';
export const GET_CANCEL_SAMPLE_DATING_OPTIONS_FAILED =
  'GET_CANCEL_SAMPLE_DATING_OPTIONS_FAILED';

export const GET_RECOMMENDATIONS_REQUESTED = 'GET_RECOMMENDATIONS_REQUESTED';
export const GET_RECOMMENDATIONS_SUCCEEDED = 'GET_RECOMMENDATIONS_SUCCEEDED';
export const GET_RECOMMENDATIONS_FAILED = 'GET_RECOMMENDATIONS_FAILED';

export const GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED';
export const GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED';
export const GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED';

export const GET_OPEN_DATES_REQUESTED = 'GET_OPEN_DATES_REQUESTED';
export const GET_OPEN_DATES_SUCCEEDED = 'GET_OPEN_DATES_SUCCEEDED';
export const GET_OPEN_DATES_FAILED = 'GET_OPEN_DATES_FAILED';

export const GET_OPEN_DATES_STATUS_REQUESTED =
  'GET_OPEN_DATES_STATUS_REQUESTED';
export const GET_OPEN_DATES_STATUS_SUCCEEDED =
  'GET_OPEN_DATES_STATUS_SUCCEEDED';
export const GET_OPEN_DATES_STATUS_FAILED = 'GET_OPEN_DATES_STATUS_FAILED';

export const REQUEST_PARTICIPATE_REQUESTED = 'REQUEST_PARTICIPATE_REQUESTED';
export const REQUEST_PARTICIPATE_SUCCEEDED = 'REQUEST_PARTICIPATE_SUCCEEDED';
export const REQUEST_PARTICIPATE_FAILED = 'REQUEST_PARTICIPATE_FAILED';

export const SET_PARTICIPATION_FAILED_NEED_ACTION =
  'SET_PARTICIPATION_FAILED_NEED_ACTION';

export const SHOW_MAINTAIN = 'SHOW_MAINTAIN';

export const CURRENT_USER_CHANGE_COST_PLAN_REQUESTED =
  'CURRENT_USER_CHANGE_COST_PLAN_REQUESTED';
export const CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED =
  'CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED';
export const CURRENT_USER_CHANGE_COST_PLAN_FAILED =
  'CURRENT_USER_CHANGE_COST_PLAN_FAILED';
export const CURRENT_USER_CANCEL_COST_PLAN_REQUESTED =
  'CURRENT_USER_CANCEL_COST_PLAN_REQUESTED';

export const SHOW_TOOLTIPS = 'SHOW_TOOLTIPS';
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS';

export const VALIDATE_DEACTIVATE_REQUESTED = 'VALIDATE_DEACTIVATE_REQUESTED';
export const VALIDATE_DEACTIVATE_SUCCEEDED = 'VALIDATE_DEACTIVATE_SUCCEEDED';
export const VALIDATE_DEACTIVATE_FAILED = 'VALIDATE_DEACTIVATE_FAILED';

export const DEACTIVE_PROFILE_REQUESTED = 'DEACTIVE_PROFILE_REQUESTED';
export const DEACTIVE_PROFILE_SUCCEEDED = 'DEACTIVE_PROFILE_SUCCEEDED';
export const DEACTIVE_PROFILE_FAILED = 'DEACTIVE_PROFILE_FAILED';

export const CANCEL_COMMIT_OPTION_REQUEST = 'CANCEL_COMMIT_OPTION_REQUEST';
export const CANCEL_COMMIT_OPTION_SUCCEEDED = 'CANCEL_COMMIT_OPTION_SUCCEEDED';
export const CANCEL_COMMIT_OPTION_FAILED = 'CANCEL_COMMIT_OPTION_FAILED';

export const PARTICIPATE_COMMIT_OPTION_REQUEST =
  'PARTICIPATE_COMMIT_OPTION_REQUEST';
export const PARTICIPATE_COMMIT_OPTION_SUCCEEDED =
  'PARTICIPATE_COMMIT_OPTION_SUCCEEDED';
export const PARTICIPATE_COMMIT_OPTION_FAILED =
  'PARTICIPATE_COMMIT_OPTION_FAILED';

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const CANCEL_PROFILE_REQUESTED = 'CANCEL_PROFILE_REQUESTED';
export const CANCEL_PROFILE_SUCCEEDED = 'CANCEL_PROFILE_SUCCEEDED';
export const CANCEL_PROFILE_FAILED = 'CANCEL_PROFILE_FAILED';

export const GET_PLAN_SUBSCRIPTION_REQUESTED =
  'GET_PLAN_SUBSCRIPTION_REQUESTED';
export const GET_PLAN_SUBSCRIPTION_SUCCEEDED =
  'GET_PLAN_SUBSCRIPTION_SUCCEEDED';
export const GET_PLAN_SUBSCRIPTION_FAILED = 'GET_PLAN_SUBSCRIPTION_FAILED';

export const GET_PLAN_REQUESTED = 'GET_PLAN_REQUESTED';
export const GET_PLAN_SUCCEEDED = 'GET_PLAN_REQUESTED_SUCCEEDED';
export const GET_PLAN_FAILED = 'GET_PLAN_REQUESTED_FAILED';

export const UPDATE_PLAN_REQUESTED = 'UPDATE_PLAN_REQUESTED';
export const UPDATE_PLAN_SUCCEEDED = 'UPDATE_PLAN_SUCCEEDED';
export const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED';

export const ADD_REQUEST_TIME_CHANGE_REQUESTED =
  'ADD_REQUEST_TIME_CHANGE_REQUESTED';
export const ADD_REQUEST_TIME_CHANGE_SUCCEEDED =
  'ADD_REQUEST_TIME_CHANGE_SUCCEEDED';
export const ADD_REQUEST_TIME_CHANGE_FAILED = 'ADD_REQUEST_TIME_CHANGE_FAILED';

export const UPDATE_REQUEST_TIME_CHANGE_REQUESTED =
  'UPDATE_REQUEST_TIME_CHANGE_REQUESTED';
export const UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED =
  'UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED';
export const UPDATE_REQUEST_TIME_CHANGE_FAILED =
  'UPDATE_REQUEST_TIME_CHANGE_FAILED';

export const SAVE_USER_FEEDBACK_REQUESTED = 'SAVE_USER_FEEDBACK_REQUESTED';
export const SAVE_USER_FEEDBACK_SUCCEEDED = 'SAVE_USER_FEEDBACK_SUCCEEDED';
export const SAVE_USER_FEEDBACK_FAILED = 'SAVE_USER_FEEDBACK_FAILED';

export const CHECK_SEND_FEEDBACK_REQUESTED = 'CHECK_SEND_FEEDBACK_REQUESTED';
export const CHECK_SEND_FEEDBACK_SUCCEEDED = 'CHECK_SEND_FEEDBACK_SUCCEEDED';
export const CHECK_SEND_FEEDBACK_FAILED = 'CHECK_SEND_FEEDBACK_FAILED';

export const USER_FEEDBACK_DETAILS_REQUESTED =
  'USER_FEEDBACK_DETAILS_REQUESTED';
export const USER_FEEDBACK_DETAILS_SUCCEEDED =
  'USER_FEEDBACK_DETAILS_SUCCEEDED';
export const USER_FEEDBACK_DETAILS_FAILED = 'USER_FEEDBACK_DETAILS_FAILED';

export const INVITE_FRIENDS_DATA_REQUESTED = 'INVITE_FRIENDS_DATA_REQUESTED';
export const INVITE_FRIENDS_DATA_SUCCEEDED = 'INVITE_FRIENDS_DATA_SUCCEEDED';
export const INVITE_FRIENDS_DATA_FAILED = 'INVITE_FRIENDS_DATA_FAILED';

//Match profile
export const GET_MATCH_PROFILE_DETAIL_REQUESTED =
  'GET_MATCH_PROFILE_DETAIL_REQUESTED';
export const GET_MATCH_PROFILE_DETAIL_SUCCEEDED =
  'GET_MATCH_PROFILE_DETAIL_SUCCEEDED';
export const GET_MATCH_PROFILE_DETAIL_FAILED =
  'GET_MATCH_PROFILE_DETAIL_FAILED';

export const MATCH_PROFILE_INFO_REQUEST = 'MATCH_PROFILE_INFO_REQUEST';
export const MATCH_PROFILE_INFO_SUCCEEDED = 'MATCH_PROFILE_INFO_SUCCEEDED';
export const MATCH_PROFILE_INFO_FAILED = 'MATCH_PROFILE_INFO_FAILED';

export const APPLY_COUPON_REQUESTED = 'APPLY_COUPON_REQUESTED';
export const APPLY_COUPON_SUCCEEDED = 'APPLY_COUPON_SUCCEEDED';
export const APPLY_COUPON_FAILED = 'APPLY_COUPON_FAILED';

export const EXCHANGE_COUPON_REQUESTED = 'EXCHANGE_COUPON_REQUESTED';
export const EXCHANGE_COUPON_SUCCEDDED = 'EXCHANGE_COUPON_SUCCEDDED';
export const EXCHANGE_COUPON_FAILED = 'EXCHANGE_COUPON_FAILED';

export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST';
export const COUPON_LIST_SUCCEEDED = 'COUPON_LIST_SUCCEEDED';
export const COUPON_LIST_FAILED = 'COUPON_LIST_FAILED';
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET';
export const COUPON_LIST_DECREMENT = 'COUPON_LIST_DECREMENT';

export const GET_COUPON_PACKAGE_REQUESTED = 'GET_COUPON_PACKAGE_REQUESTED';
export const GET_COUPON_PACKAGE_SUCCEEDED = 'GET_COUPON_PACKAGE_SUCCEEDED';
export const GET_COUPON_PACKAGE_FAILED = 'GET_COUPON_PACKAGE_FAILED';
export const PURCHASE_COUPON_PACKAGE_REQUEST =
  'PURCHASE_COUPON_PACKAGE_REQUEST';
export const PURCHASE_COUPON_PACKAGE_SUCCEEDED =
  'PURCHASE_COUPON_PACKAGE_SUCCEEDED';
export const PURCHASE_COUPON_PACKAGE_FAILED = 'PURCHASE_COUPON_PACKAGE_FAILED';

export const REMATCH_REQUESTED = 'REMATCH_REQUESTED';
export const REMATCH_SUCCEEDED = 'REMATCH_SUCCEEDED';
export const REMATCH_FAILED = 'REMATCH_FAILED';

export const EVALUATION_INFO_REQUEST = 'EVALUATION_INFO_REQUEST';
export const EVALUATION_INFO_SUCCEEDED = 'EVALUATION_INFO_SUCCEEDED';
export const EVALUATION_INFO_FAILED = 'EVALUATION_INFO_FAILED';

export const EVALUATION_LIST_REQUEST = 'EVALUATION_LIST_REQUEST';
export const EVALUATION_LIST_SUCCEEDED = 'EVALUATION_LIST_SUCCEEDED';
export const EVALUATION_LIST_FAILED = 'EVALUATION_LIST_FAILED';

// registration form
export const REGISTRATION_FORM_GET_STEP_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SUCCEEDED =
  'REGISTRATION_FORM_GET_STEP_SUCCEEDED';
export const REGISTRATION_FORM_GET_STEP_FAILED =
  'REGISTRATION_FORM_GET_STEP_FAILED';
//upload image
export const STORE_IMAGE_REQUESTED = 'STORE_IMAGE_REQUESTED';
export const STORE_IMAGE_SUCCEDDED = 'STORE_IMAGE_SUCCEDDED';
export const STORE_IMAGE_FAILED = 'STORE_IMAGE_FAILED';
//STEP1
export const REGISTRATION_FORM_GET_STEP_ONE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_ONE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_ONE_FAILED =
  'REGISTRATION_FORM_GET_STEP_ONE_FAILED';
export const REGISTRATION_FORM_POST_STEP_ONE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_ONE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_ONE_FAILED =
  'REGISTRATION_FORM_POST_STEP_ONE_FAILED';

export const SET_USER_INFO_WHEN_API_GET_NOT_CALL =
  'SET_USER_INFO_WHEN_API_GET_NOT_CALL';

//STEP 1 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED';

//STEP 2
export const REGISTRATION_FORM_GET_STEP_TWO_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_TWO_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_TWO_FAILED =
  'REGISTRATION_FORM_GET_STEP_TWO_FAILED';
export const REGISTRATION_FORM_POST_STEP_TWO_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_TWO_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_TWO_FAILED =
  'REGISTRATION_FORM_POST_STEP_TWO_FAILED';

//STEP 2 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED';

//STEP 3
export const REGISTRATION_FORM_GET_STEP_THREE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_THREE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_THREE_FAILED =
  'REGISTRATION_FORM_GET_STEP_THREE_FAILED';
export const REGISTRATION_FORM_POST_STEP_THREE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_THREE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_THREE_FAILED =
  'REGISTRATION_FORM_POST_STEP_THREE_FAILED';

//STEP 3 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED';

//STEP 4
export const REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_FOUR_FAILED =
  'REGISTRATION_FORM_GET_STEP_FOUR_FAILED';
export const REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_FOUR_FAILED =
  'REGISTRATION_FORM_POST_STEP_FOUR_FAILED';

//STEP 4 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED';

//STEP5
export const REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_FIVE_FAILED =
  'REGISTRATION_FORM_GET_STEP_FIVE_FAILED';
export const REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_FIVE_FAILED =
  'REGISTRATION_FORM_POST_STEP_FIVE_FAILED';

//STEP 5 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED';

//STEP6
export const REGISTRATION_FORM_GET_STEP_SIX_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_SIX_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_SIX_FAILED =
  'REGISTRATION_FORM_GET_STEP_SIX_FAILED';
export const REGISTRATION_FORM_POST_STEP_SIX_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_SIX_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_SIX_FAILED =
  'REGISTRATION_FORM_POST_STEP_SIX_FAILED';

//Step 6 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED';

//Step 6-5 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX__FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX__FIVE_FAILED';

//step7
export const REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_FAILED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_FAILED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_FAILED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_FAILED';

//STEP 7-5
export const REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_FAILED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_FAILED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_FAILED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_FAILED';

//Step7 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED';

//Step 8
export const REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_EIGHT_FAILED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_FAILED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_FAILED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_FAILED';

//Step 8 (2nd)

export const UPDATE_PREFECTURE_AND_AREA_REQUESTED =
  'UPDATE_PREFECTURE_AND_AREA_REQUESTED';
export const UPDATE_PREFECTURE_AND_AREA_SUCCEDDED =
  'UPDATE_PREFECTURE_AND_AREA_SUCCEDDED';
export const UPDATE_PREFECTURE_AND_AREA_FAILED =
  'UPDATE_PREFECTURE_AND_AREA_FAILED';

export const CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED';
export const CONDITION_CHANGE_PREFECTURE_AND_AREA_SUCCEDDED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_SUCCEDDED';
export const CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED';

export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED';

export const REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_FAILED =
  'REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_FAILED';
export const REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_FAILED =
  'REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_FAILED';

export const REACTIVATION_RE_APPROVE_REQUEST =
  'REACTIVATION_RE_APPROVE_REQUEST';
export const REACTIVATION_RE_APPROVE_SUCCEEDED =
  'REACTIVATION_RE_APPROVE_SUCCEEDED';
export const REACTIVATION_RE_APPROVE_FAILED = 'REACTIVATION_RE_APPROVE_FAILED';
export const RE_REGISTER_REQUEST = 'RE-REGISTER_REQUEST';
export const RE_REGISTER_SUCCEEDED = 'RE_REGISTER_SUCCEEDED';
export const RE_REGISTER_FAILED = 'RE_REGISTER_FAILED';
export const GET_CARDS_REQUESTED = 'GET_CARDS_REQUESTED';
export const GET_CARDS_SUCCEEDED = 'GET_CARDS_SUCCEEDED';
export const GET_CARDS_FAILED = 'GET_CARDS_FAILED';
export const CREATE_CARD_REQUESTED = 'CREATE_CARD_REQUESTED';
export const CREATE_CARD_SUCCEEDED = 'CREATE_CARD_SUCCEEDED';
export const CREATE_CARD_FAILED = 'CREATE_CARD_FAILED';
export const SET_DEFAULT_CARD_REQUESTED = 'SET_DEFAULT_CARD_REQUESTED';
export const SET_DEFAULT_CARD_SUCCEEDED = 'SET_DEFAULT_CARD_SUCCEEDED';
export const SET_DEFAULT_CARD_FAIL = 'SET_DEFAULT_CARD_FAIL';

export const UPDATE_GLOBAL_ERROR_POPUP = 'UPDATE_GLOBAL_ERROR_POPUP';
export const RESET_GLOBAL_ERROR_POPUP = 'RESET_GLOBAL_ERROR_POPUP';

export const UPDATE_EMAIL_REQUESTED = 'UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_SUCCEEDED = 'UPDATE_EMAIL_SUCCEEDED';
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED';

export const STOP_RECEIVE_NOTIFICATIONS_REQUESTED =
  'STOP_RECEIVE_NOTIFICATIONS_REQUESTED';
export const STOP_RECEIVE_NOTIFICATIONS_SUCCEEDED =
  'STOP_RECEIVE_NOTIFICATIONS_SUCCEEDED';
export const STOP_RECEIVE_NOTIFICATIONS_FAILED =
  'STOP_RECEIVE_NOTIFICATIONS_FAILED';

export const GET_STOP_NOTIFICATION_CATEGORIES_REQUESTED =
  'GET_STOP_NOTIFICATION_CATEGORIES_REQUESTED';
export const GET_STOP_NOTIFICATION_CATEGORIES_SUCCEEDED =
  'GET_STOP_NOTIFICATION_CATEGORIES_SUCCEEDED';
export const GET_STOP_NOTIFICATION_CATEGORIES_FAILED =
  'GET_STOP_NOTIFICATION_CATEGORIES_FAILED';

export const GET_MY_PAGE_INFO_REQUESTED = 'GET_MY_PAGE_INFO_REQUESTED';
export const GET_MY_PAGE_INFO_SUCCEEDED = 'GET_MY_PAGE_INFO_SUCCEEDED';
export const GET_MY_PAGE_INFO_FAILED = 'GET_MY_PAGE_INFO_FAILED';

export const GET_CURRENT_SERVER_TIME_REQUESTED =
  'GET_CURRENT_SERVER_TIME_REQUESTED';
export const GET_CURRENT_SERVER_TIME_SUCCEEDED =
  'GET_CURRENT_SERVER_TIME_SUCCEEDED';
export const GET_CURRENT_SERVER_TIME_FAILED = 'GET_CURRENT_SERVER_TIME_FAILED';

export const GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED =
  'GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED';
export const GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED =
  'GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED';
export const GET_DATING_DAY_ABLE_TO_CHAT_FAILED =
  'GET_DATING_DAY_ABLE_TO_CHAT_FAILED';

export const GET_USER_GLOBAL_INFO_REQUESTED = 'GET_USER_GLOBAL_INFO_REQUESTED';
export const GET_USER_GLOBAL_INFO_SUCCEEDED = 'GET_USER_GLOBAL_INFO_SUCCEEDED';
export const GET_USER_GLOBAL_INFO_FAILD = 'GET_USER_GLOBAL_INFO_FAILD';

export const ACCESS_CHAT_ROOM_REQUESTED = 'ACCESS_CHAT_ROOM_REQUESTED';
export const ACCESS_CHAT_ROOM_SUCCEEDED = 'ACCESS_CHAT_ROOM_SUCCEEDED';
export const ACCESS_CHAT_ROOM_FAILED = 'ACCESS_CHAT_ROOM_FAILED';

export const UPDATE_DATING_STATUS_REQUESTED = 'UPDATE_DATING_STATUS_REQUESTED';
export const UPDATE_DATING_STATUS_SUCCEEDED = 'UPDATE_DATING_STATUS_SUCCEEDED';
export const UPDATE_DATING_STATUS_FAILED = 'UPDATE_DATING_STATUS_FAILED';

export const UPDATE_MULTIPLE_DATING_STATUS_REQUESTED =
  'UPDATE_MULTIPLE_DATING_STATUS_REQUESTED';
export const UPDATE_MULTIPLE_DATING_STATUS_SUCCEEDED =
  'UPDATE_MULTIPLE_DATING_STATUS_SUCCEEDED';
export const UPDATE_MULTIPLE_DATING_STATUS_FAILED =
  'UPDATE_MULTIPLE_DATING_STATUS_FAILED';

export const UPDATE_TEMP_CANCEL_TRIAL_REQUESTED =
  'UPDATE_TEMP_CANCEL_TRIAL_REQUESTED';
export const UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED =
  'UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED';
export const UPDATE_TEMP_CANCEL_TRIAL_FAILED =
  'UPDATE_TEMP_CANCEL_TRIAL_FAILED';

export const GET_USER_INFO_FB_PIXEL_REQUESTED =
  'GET_USER_INFO_FB_PIXEL_REQUESTED';
export const GET_USER_INFO_FB_PIXEL_SUCCEEDED =
  'GET_USER_INFO_FB_PIXEL_SUCCEEDED';
export const GET_USER_INFO_FB_PIXEL_FAILD = 'GET_USER_INFO_FB_PIXEL_FAILD';
export const SET_FIRST_INIT_FB_PIXEL_GENDER = 'SET_FIRST_INIT_FB_PIXEL_GENDER';

export const VALIDATE_SELECTING_REQUESTED = 'VALIDATE_SELECTING_REQUESTED';
export const VALIDATE_SELECTING_SUCCEEDED = 'VALIDATE_SELECTING_SUCCEEDED';
export const VALIDATE_SELECTING_FAILED = 'VALIDATE_SELECTING_FAILED';

export const SEND_EXCEPTION_NOTICE_REQUESTED =
  'SEND_EXCEPTION_NOTICE_REQUESTED';
export const SEND_EXCEPTION_NOTICE_SUCCEEDED =
  'SEND_EXCEPTION_NOTICE_SUCCEEDED';
export const SEND_EXCEPTION_NOTICE_FAILED = 'SEND_EXCEPTION_NOTICE_FAILED';

export const VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED =
  'VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED';
export const VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED =
  'VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED';
export const VALIDATE_BEFORE_UPDATE_PLAN_FAILED =
  'VALIDATE_BEFORE_UPDATE_PLAN_FAILED';

export const SUBSCRIPTION_PAUSED_REQUEST = 'SUBSCRIPTION_PAUSED_REQUEST';
export const SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED =
  'SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_PAUSED_REQUEST_FAILED =
  'SUBSCRIPTION_PAUSED_REQUEST_FAILED';

export const CANCEL_SUBSCRIPTION_PAUSED_REQUEST =
  'CANCEL_SUBSCRIPTION_PAUSED_REQUEST';
export const CANCEL_SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED =
  'CANCEL_SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED';
export const CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED =
  'CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED';

export const GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_REQUESTED =
  'GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_REQUESTED';
export const GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_SUCCEEDED =
  'GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_SUCCEEDED';
export const GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED =
  'GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED';

export const CONTINUE_SUBSCRIPTION_PAUSED = 'CONTINUE_SUBSCRIPTION_PAUSED';

export const UPDATE_USER_MOTIVATED_DATING_REQUESTED =
  'UPDATE_USER_MOTIVATED_DATING_REQUESTED';
export const UPDATE_USER_MOTIVATED_DATING_SUCCEEDED =
  'UPDATE_USER_MOTIVATED_DATING_SUCCEEDED';
export const UPDATE_USER_MOTIVATED_DATING_FAILED =
  'UPDATE_USER_MOTIVATED_DATING_FAILED';

export const SAVE_USER_FEEDBACK_COMMIT_OPTION_REQUESTED =
  'SAVE_USER_FEEDBACK_COMMIT_OPTION_REQUESTED';
export const SAVE_USER_FEEDBACK_COMMIT_OPTION_SUCCEEDED =
  'SAVE_USER_FEEDBACK_COMMIT_OPTION_SUCCEEDED';
export const SAVE_USER_FEEDBACK_COMMIT_OPTION_FAILED =
  'SAVE_USER_FEEDBACK_COMMIT_OPTION_FAILED';

export const GET_SCHEDULED_PLAN_REQUESTED = 'GET_SCHEDULED_PLAN_REQUESTED';
export const GET_SCHEDULED_PLAN_SUCCEEDED = 'GET_SCHEDULED_PLAN_SUCCEEDED';
export const GET_SCHEDULED_PLAN_FAILED = 'GET_SCHEDULED_PLAN_FAILED';

export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED';
export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_SUCCEEDED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_SUCCEEDED';
export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED';

export const UPDATE_READ_STATUS_FOR_NOTICE_POPUP_REQUESTED =
  'UPDATE_READ_STATUS_FOR_NOTICE_POPUP_REQUESTED';
export const UPDATE_READ_STATUS_FOR_NOTICE_POPUP_SUCCEEDED =
  'UPDATE_READ_STATUS_FOR_NOTICE_POPUP_SUCCEEDED';
export const UPDATE_READ_STATUS_FOR_NOTICE_POPUP_FAILED =
  'UPDATE_READ_STATUS_FOR_NOTICE_POPUP_FAILED';
export const GET_NOTICE_POPUP_REQUESTED = 'GET_NOTICE_POPUP_REQUESTED';
export const GET_NOTICE_POPUP_SUCCEEDED = 'GET_NOTICE_POPUP_SUCCEEDED';
export const GET_NOTICE_POPUP_FAILED = 'GET_NOTICE_POPUP_FAILED';

export const USER_NEED_TO_UPDATE_NEW_PREFERENCES_REQUESTED =
  'USER_NEED_TO_UPDATE_NEW_PREFERENCES_REQUESTED';
export const USER_NEED_TO_UPDATE_NEW_PREFERENCES_SUCCEEDED =
  'USER_NEED_TO_UPDATE_NEW_PREFERENCES_SUCCEEDED';
export const USER_NEED_TO_UPDATE_NEW_PREFERENCES_FAILED =
  'USER_NEED_TO_UPDATE_NEW_PREFERENCES_FAILED';
export const HIDE_NEED_TO_UPDATE_NEW_PREFERENCES_POPUP =
  'HIDE_NEED_TO_UPDATE_NEW_PREFERENCES_POPUP';

export const SET_WHITE_MODE = 'SET_WHITE_MODE';

export const SET_NATIVE_MODE = 'SET_NATIVE_MODE';

export const REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_REQUESTED =
  'REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_REQUESTED';
export const REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_SUCCEEDED =
  'REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_SUCCEEDED';
export const REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_FAILED =
  'REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_FAILED';

//User certification
export const USER_CERTIFICATION_REQUESTED = 'USER_CERTIFICATION_REQUESTED';
export const USER_CERTIFICATION_SUCCEEDED = 'USER_CERTIFICATION_SUCCEEDED';
export const USER_CERTIFICATION_FAILED = 'USER_CERTIFICATION_FAILED';
export const UPDATE_USER_CERTIFICATION_REQUESTED =
  'UPDATE_USER_CERTIFICATION_REQUESTED';
export const UPDATE_USER_CERTIFICATION_SUCCEEDED =
  'UPDATE_USER_CERTIFICATION_SUCCEEDED';
export const UPDATE_USER_CERTIFICATION_FAILED =
  'UPDATE_USER_CERTIFICATION_FAILED';
export const STORE_IMAGE_USER_CERTIFICATION_REQUESTED =
  'STORE_IMAGE_USER_CERTIFICATION_REQUESTED';
export const STORE_IMAGE_USER_CERTIFICATION_SUCCEEDED =
  'STORE_IMAGE_USER_CERTIFICATION_SUCCEEDED';
export const STORE_IMAGE_USER_CERTIFICATION_FAILED =
  'STORE_IMAGE_USER_CERTIFICATION_FAILED';

export const STORE_BANNER_FUNCTION_REQUEST = 'STORE_BANNER_FUNCTION_REQUEST';
export const STORE_BANNER_FUNCTION_SUCCEEDED =
  'STORE_BANNER_FUNCTION_SUCCEEDED';
export const STORE_BANNER_FUNCTION_FAILED = 'STORE_BANNER_FUNCTION_FAILED';

export const CHANGE_SHOW_NEW_CHAT_DESIGN_REQUESTED =
  'CHANGE_SHOW_NEW_CHAT_DESIGN_REQUESTED';
export const CHANGE_SHOW_NEW_CHAT_DESIGN_SUCCEDDED =
  'CHANGE_SHOW_NEW_CHAT_DESIGN_SUCCEDDED';
export const CHANGE_SHOW_NEW_CHAT_DESIGN_FAILED =
  'CHANGE_SHOW_NEW_CHAT_DESIGN_FAILED';

export const GET_AVAILABLE_FIXED_QUESTION_REQUESTED =
  'GET_AVAILABLE_FIXED_QUESTION_REQUESTED';
export const GET_AVAILABLE_FIXED_QUESTION_SUCCEEDED =
  'GET_AVAILABLE_FIXED_QUESTION_SUCCEEDED';
export const GET_AVAILABLE_FIXED_QUESTION_FAILED =
  'GET_AVAILABLE_FIXED_QUESTION_FAILED';

export const GET_CANCEL_DEACTIVE_APP_OPTIONS_REQUESTED =
  'GET_CANCEL_DEACTIVE_APP_OPTIONS_REQUESTED';
export const GET_CANCEL_DEACTIVE_APP_OPTIONS_SUCCEEDED =
  'GET_CANCEL_DEACTIVE_APP_OPTIONS_SUCCEEDED';
export const GET_CANCEL_DEACTIVE_APP_OPTIONS_FAILED =
  'GET_CANCEL_DEACTIVE_APP_OPTIONS_FAILED';

export const REQUEST_FIRST_LOGIN_AFTER_APPROVED_REQUESTED =
  'REQUEST_FIRST_LOGIN_AFTER_APPROVED_REQUESTED';
export const REQUEST_FIRST_LOGIN_AFTER_APPROVED_SUCCEEDED =
  'REQUEST_FIRST_LOGIN_AFTER_APPROVED_SUCCEEDED';
export const REQUEST_FIRST_LOGIN_AFTER_APPROVED_FAILED =
  'REQUEST_FIRST_LOGIN_AFTER_APPROVED_FAILED';

export const GET_BANNER_SLIDE_DATA = 'GET_BANNER_SLIDE_DATA';
export const GET_BANNER_SLIDE_SUCCEEDED = 'GET_BANNER_SLIDE_SUCCEEDED';
export const GET_BANNER_SLIDE_FAILED = 'GET_BANNER_SLIDE_FAILED';
//User rank
export const USER_RANK_INFO_REQUESTED = 'USER_RANK_INFO_REQUESTED';
export const USER_RANK_INFO_SUCCEEDED = 'USER_RANK_INFO_SUCCEEDED';
export const USER_RANK_INFO_FAILED = 'USER_RANK_INFO_FAILED';
export const UP_RANK_REQUESTED = 'UP_RANK_REQUESTED';
export const UP_RANK_SUCCEEDED = 'UP_RANK_SUCCEEDED';
export const UP_RANK_FAILED = 'UP_RANK_FAILED';
export const REMAIN_RANK_REQUESTED = 'REMAIN_RANK_REQUESTED';
export const REMAIN_RANK_SUCCEEDED = 'REMAIN_RANK_SUCCEEDED';
export const REMAIN_RANK_FAILED = 'REMAIN_RANK_FAILED';
export const APPROVEED_OR_REJECTED_PROMOTE_RANK_REQUESTED = 'APPROVEED_OR_REJECTED_PROMOTE_RANK_REQUESTED';
export const APPROVEED_OR_REJECTED_PROMOTE_RANK_SUCCEEDED = 'APPROVEED_OR_REJECTED_PROMOTE_RANK_SUCCEEDED';
export const APPROVEED_OR_REJECTED_PROMOTE_RANK_FAILED = 'APPROVEED_OR_REJECTED_PROMOTE_RANK_FAILED';

export const GET_EMAIL_RESERVATION_REQUESTED =
  'GET_EMAIL_RESERVATION_REQUESTED';
export const GET_EMAIL_RESERVATION_SUCCEEDED =
  'GET_EMAIL_RESERVATION_SUCCEEDED';
export const GET_EMAIL_RESERVATION_FAILED = 'GET_EMAIL_RESERVATION_FAILED';

// FACE TYPE
export const GET_FACE_TYPE_REQUESTED = 'GET_FACE_TYPE_REQUESTED';
export const GET_FACE_TYPE_SUCCEEDED = 'GET_FACE_TYPE_SUCCEEDED';
export const GET_FACE_TYPE_FAILED = 'GET_FACE_TYPE_FAILED';

export const UPDATE_FACE_TYPE_ONE_REQUESTED = 'UPDATE_FACE_TYPE_ONE_REQUESTED';
export const UPDATE_FACE_TYPE_ONE_SUCCEEDED = 'UPDATE_FACE_TYPE_ONE_SUCCEEDED';
export const UPDATE_FACE_TYPE_ONE_FAILED = 'UPDATE_FACE_TYPE_ONE_FAILED';

export const UPDATE_FACE_TYPE_REQUESTED = 'UPDATE_FACE_TYPE_REQUESTED';
export const UPDATE_FACE_TYPE_SUCCEEDED = 'UPDATE_FACE_TYPE_SUCCEEDED';
export const UPDATE_FACE_TYPE_FAILED = 'UPDATE_FACE_TYPE_FAILED';
export const USER_FORCE_LOGIN_REQUESTED = 'USER_FORCE_LOGIN_REQUESTED';
export const USER_FORCE_LOGIN_SUCCEEDED = 'USER_FORCE_LOGIN_SUCCEEDED';
export const USER_FORCE_LOGIN_FAILED = 'USER_FORCE_LOGIN_FAILED';

export const CLEAR_ACTIVE_SUBSCRIPTION_PAUSE_POPUP = 'CLEAR_ACTIVE_SUBSCRIPTION_PAUSE_POPUP';
export const CHANGE_SAMPLE_PARTICIPATION_REQUESTED = 'CHANGE_SAMPLE_PARTICIPATION_REQUESTED';
export const CHANGE_SAMPLE_PARTICIPATION_SUCCEEDED = 'CHANGE_SAMPLE_PARTICIPATION_SUCCEEDED';
export const CHANGE_SAMPLE_PARTICIPATION_FAILED = 'CHANGE_SAMPLE_PARTICIPATION_FAILED';

//USER PARTICIPATION ITEMS
export const USER_PARTICIPATION_ITEMS_REQUESTED = 'USER_PARTICIPATION_ITEMS_REQUESTED';
export const USER_PARTICIPATION_ITEMS_SUCCEEDED = 'USER_PARTICIPATION_ITEMS_SUCCEEDED';
export const USER_PARTICIPATION_ITEMS_FAILED = 'USER_PARTICIPATION_ITEMS_FAILED';

export const UPDATE_USER_PARTICIPATION_ITEMS_REQUESTED = 'UPDATE_USER_PARTICIPATION_ITEMS_REQUESTED';
export const UPDATE_USER_PARTICIPATION_ITEMS_SUCCEEDED = 'UPDATE_USER_PARTICIPATION_ITEMS_SUCCEEDED';
export const UPDATE_USER_PARTICIPATION_ITEMS_FAILED = 'UPDATE_USER_PARTICIPATION_ITEMS_FAILED';

export const UPDATE_USER_PARTICIPATION_ITEMS_STATUS_REQUESTED = 'UPDATE_USER_PARTICIPATION_ITEMS_STATUS_REQUESTED';
export const UPDATE_USER_PARTICIPATION_ITEMS_STATUS_SUCCEEDED = 'UPDATE_USER_PARTICIPATION_ITEMS_STATUS_SUCCEEDED';
export const UPDATE_USER_PARTICIPATION_ITEMS_STATUS_FAILED = 'UPDATE_USER_PARTICIPATION_ITEMS_STATUS_FAILED';

export const PURCHASE_DATING_ITEMS_REQUESTED = 'PURCHASE_DATING_ITEMS_REQUESTED';
export const PURCHASE_DATING_ITEMS_SUCCEEDED = 'PURCHASE_DATING_ITEMS_SUCCEEDED';
export const PURCHASE_DATING_ITEMS_FAILED = 'PURCHASE_DATING_ITEMS_FAILED';

export const USER_OWNED_PARTICIPATION_ITEMS_REQUESTED = 'USER_OWNED_PARTICIPATION_ITEMS_REQUESTED';
export const USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED = 'USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED';
export const USER_OWNED_PARTICIPATION_ITEMS_FAILED = 'USER_OWNED_PARTICIPATION_ITEMS_FAILED';

export const UPDATE_USER_PARTICIPATION_ITEMS = 'UPDATE_USER_PARTICIPATION_ITEMS';
export const DELETE_USER_PARTICIPATION_ITEM = 'DELETE_USER_PARTICIPATION_ITEM';
export const CLEAR_LATEST_PARTICIPATION_ITEMS_DATA = 'CLEAR_LATEST_PARTICIPATION_ITEMS_DATA';

export const GET_USER_TRIAL_INFO_REQUESTED = 'GET_USER_TRIAL_INFO_REQUESTED';
export const GET_USER_TRIAL_INFO_SUCCEEDED = 'GET_USER_TRIAL_INFO_SUCCEEDED';
export const GET_USER_TRIAL_INFO_FAILED = 'GET_USER_TRIAL_INFO_FAILED';

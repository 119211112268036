import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import ReactGA from "react-ga4";
import GTagManager from 'react-gtm-module';
import { path } from 'ramda';
import { store } from '../redux/store';
import { gtmID, gaID , dev_gtmID, dev_gaID} from '../constants/constant';

// 本番と開発環境GA振り分け
if(window.__env__.ENVIRONMENT === 'production'){
  var gtagID = window.__env__.GOOGLE_ANALYTICS_TRACKING_ID || gaID;
  GoogleAnalytics.initialize(gtagID);
}else{
  ReactGA.initialize(dev_gaID);
}

const tagManagerArgs = {
  gtmId: (window.__env__.ENVIRONMENT === 'production')? window.__env__.GOOGLE_TAG_MANAGER_ID || gtmID : dev_gtmID
};

GTagManager.initialize(tagManagerArgs);

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = ({ page, userId }) => {

    if(window.__env__.ENVIRONMENT === 'production'){
      // Set Google Analytics
      GoogleAnalytics.set({
        page: page,
        userId: userId || null,
        ...options
      });
      GoogleAnalytics.pageview(page);
    }else{
      // 開発環境用GA4
      ReactGA.send({
        hitType: "pageview",
        page: page,
        userId: userId || null,
        ...options
      });
    }

    // Set Google Tag Manager
    GTagManager.dataLayer({
      dataLayer: {
        userId: userId || null
      }
    });
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      // grab current state
      const state = store.getState();
      const userId = path(
        ['auth', 'userdata', 'userAuth', 'user', 'id'],
        state
      );
      trackPage({ page, userId });
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const state = store.getState();
      const currentUserId = path(
        ['auth', 'userdata', 'userAuth', 'user', 'id'],
        state
      );
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage({ page: nextPage, userId: currentUserId });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;

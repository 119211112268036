import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* chatRoom(action) {
  const params = action.params;
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_CHAT_ROOM +
        `?user_id=${params.user_id}&partner_id=${params.partner_id}&room_code=${params.room_code}&dating_id=${params.dating_id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHAT_ROOM_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CHAT_ROOM_REQUEST_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHAT_ROOM_REQUEST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getTwilioToken(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.GET_TWILIO_TOKEN,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_TWILIO_TOKEN_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_TWILIO_TOKEN_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_TWILIO_TOKEN_FAILED, error });
  }
}

function* accessChatRoom(action) {
  const params = action.params;
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_ACCESS_CHAT_ROOM + `/${params.id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.ACCESS_CHAT_ROOM_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.ACCESS_CHAT_ROOM_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.ACCESS_CHAT_ROOM_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetTwilioToken() {
  yield takeLatest(types.GET_TWILIO_TOKEN_REQUESTED, getTwilioToken);
}

export function* watchChatRoom() {
  yield takeLatest(types.CHAT_ROOM_REQUESTED, chatRoom);
}

export function* watchAccessChatRoom() {
  yield takeLatest(types.ACCESS_CHAT_ROOM_REQUESTED, accessChatRoom);
}

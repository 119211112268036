import * as types from '../actions/types';

const initialState = {
  data: {},
  isError: false
};

const userGlobalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_GLOBAL_INFO_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_USER_GLOBAL_INFO_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null,
        isError: false
      };
    case types.GET_USER_GLOBAL_INFO_FAILD:
      return {
        ...state,
        isError: true,
        data: null,
        error: action.error
      };

    case types.SET_USER_INFO_WHEN_API_GET_NOT_CALL:
      return {
        ...state,
        data: action.data,
        error: null,
        isError: false
      };

    default:
      return state;
  }
};

export default userGlobalInfoReducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';
import { checkActiveSubscriptionPause } from '@utils';

function* setParticipation(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.SET_PARTICIPATION,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SET_PARTICIPATION_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({ type: types.SET_PARTICIPATION_FAILED, error: response });
    } else if (response.status === ResponseStatus.PROFILE_MISSING_EMAIL) {
      yield put({
        type: types.SET_PARTICIPATION_FAILED_NEED_ACTION
      });
    } else {
      yield put({ type: types.SET_PARTICIPATION_FAILED, error: response });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SET_PARTICIPATION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchSetParticipation() {
  yield takeLatest(types.SET_PARTICIPATION_REQUESTED, setParticipation);
}

function* getOpenDates(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/get-dating-days',
      API.GET,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_OPEN_DATES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_OPEN_DATES_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_OPEN_DATES_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getOpenDateStatus(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/get-open-dates-status',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_OPEN_DATES_STATUS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_OPEN_DATES_STATUS_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_OPEN_DATES_STATUS_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* requestParticipate(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + `/request-participate`,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REQUEST_PARTICIPATE_SUCCEEDED,
        data: response.data
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({ type: types.REQUEST_PARTICIPATE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REQUEST_PARTICIPATE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelParticipate(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/cancel-participate',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_PARTICIPATE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CANCEL_PARTICIPATE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_PARTICIPATE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelSampleParticipant(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/cancel-sample-participate',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CANCEL_SAMPLE_PARTICIPANT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_SAMPLE_PARTICIPANT_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* removeFirstLoginAfterApproved(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_FIRST_LOGIN_AFTER_APPROVED,
      API.POST
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REQUEST_FIRST_LOGIN_AFTER_APPROVED_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.REQUEST_FIRST_LOGIN_AFTER_APPROVED_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REQUEST_FIRST_LOGIN_AFTER_APPROVED_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getRecommendations(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/get-recommendations',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_RECOMMENDATIONS_REQUESTED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_RECOMMENDATIONS_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_RECOMMENDATIONS_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getAwaitingParticipantDatingDays(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/get-awaiting-participant-datingDays',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* changeSampleParticipation(action) {
  let response = null;
  try {
    response = yield call(
        requestAPI,
        API.API_PARTICIPATION + '/change-sample-participate',
        API.POST,
        action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHANGE_SAMPLE_PARTICIPATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CHANGE_SAMPLE_PARTICIPATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* getMotivateImages(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
        requestAPI,
        API.GET_MOTIVATE_IMAGES,
        API.GET,
        action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_MOTIVATE_IMAGES_SUCCEEDED,
        data: response.data
      });
    } else { 
      yield put({
        type: types.GET_MOTIVATE_IMAGES_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_MOTIVATE_IMAGES_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* getUserTrialInfo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
        requestAPI,
        API.GET_USER_TRIAL_INFO,
        API.GET,
        action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_TRIAL_INFO_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_USER_TRIAL_INFO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_USER_TRIAL_INFO_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getCancelSampleDatingOptions(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_CANCEL_SAMPLE_DATING_OPTIONS,
      API.GET,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CANCEL_SAMPLE_DATING_OPTIONS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_CANCEL_SAMPLE_DATING_OPTIONS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_CANCEL_SAMPLE_DATING_OPTIONS_FAILED,
      error: response
    });
  }
  store.dispatch(hideLoading());
}

export function* watchGetOpenDates() {
  yield takeLatest(types.GET_OPEN_DATES_REQUESTED, getOpenDates);
}

export function* watchGetRecommendations() {
  yield takeLatest(types.GET_RECOMMENDATIONS_REQUESTED, getRecommendations);
}
export function* watchRequestParticipate() {
  yield takeLatest(types.REQUEST_PARTICIPATE_REQUESTED, requestParticipate);
}
export function* watchCancelParticipate() {
  yield takeLatest(types.CANCEL_PARTICIPATE_REQUESTED, cancelParticipate);
}
export function* watchCancelSampleParticipant() {
  yield takeLatest(
    types.CANCEL_SAMPLE_PARTICIPANT_REQUESTED,
    cancelSampleParticipant
  );
}
export function* watchGetAwaitingParticipantDatingDays() {
  yield takeLatest(
    types.GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED,
    getAwaitingParticipantDatingDays
  );
}
export function* watchGetOpenDateStatus() {
  yield takeLatest(types.GET_OPEN_DATES_STATUS_REQUESTED, getOpenDateStatus);
}

export function* watchRemoveFirstLoginAfterApprove(){
  yield takeLatest(types.REQUEST_FIRST_LOGIN_AFTER_APPROVED_REQUESTED, removeFirstLoginAfterApproved);
}

export function* watchChangeSampleParticipation() {
  yield takeLatest(
      types.CHANGE_SAMPLE_PARTICIPATION_REQUESTED,
      changeSampleParticipation
  );
}
export function* watchGetMotivateImages() {
  yield takeLatest(types.GET_MOTIVATE_IMAGES_REQUESTED, getMotivateImages);
}
export function* watchGetUserTrialInfo() {
  yield takeLatest(types.GET_USER_TRIAL_INFO_REQUESTED, getUserTrialInfo);
}

export function* watchGetCancelSampleDatingOptions() {
  yield takeLatest(
    types.GET_CANCEL_SAMPLE_DATING_OPTIONS_REQUESTED,
    getCancelSampleDatingOptions
  );
}
import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import TextRead from '@components/ChatFeature/TextRead';
import TextUnread from '@components/ChatFeature/TextUnread';
import Autolinker from 'autolinker';
import classnames from 'classnames';
import close from '@assets/common/close.png';
import PropTypes from 'prop-types';
//
// Shows the base user's text bubble. Appears to the right of screen.
// -----------------------
const UserTextMessage = ({
  message,
  read,
  onUnreadClick,
  createDate,
  createAt,
  isWhiteMode,
  type,
  url
}) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState('');

  useEffect(() => {
    url.then(res => setSrc(res));
  }, []);

  const handleOpenImage = () => {
    setOpen(true);
  };

  const handleCloseImage = () => {
    setOpen(false);
  };
  return (
    <>
      <span
        className={classnames(styles.userTextMessage, {
          [styles.userTextShowDate]: createDate
        })}
      >
        <span>
          {read ? <TextRead /> : <TextUnread onUnreadClick={onUnreadClick} />}
          {createAt ? (
            <div className={styles.chatUserCreateTime}>
              {createAt.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })}
            </div>
          ) : null}
        </span>
        {type === 'media' ? (
          <div
            className={styles.chatUserImageWrapper}
            onClick={handleOpenImage}
          >
            <img src={src} alt="" className={styles.chatUserImage} />
          </div>
        ) : (
          <div
            className={classnames(styles.chatUserTextBubble, {
              [styles.activeWhiteMode]: isWhiteMode
            })}
            dangerouslySetInnerHTML={{ __html: htmlMessage }}
          />
        )}
        {createDate ? (
          <div className={styles.chatUserCreateDate}>{createDate}</div>
        ) : null}
      </span>
      {open && (
        <>
          <div className={styles.chatUserImagePreviewWrapper}>
            <div className={styles.chatUserImagePreview}>
              <img src={src} alt="img" className={styles.imagePreviewFull} />
            </div>
          </div>
          <img
            src={close}
            alt="close"
            onClick={handleCloseImage}
            className={styles.closeImage}
          />
        </>
      )}
    </>
  );
};
UserTextMessage.propTypes = {
  message: PropTypes.string,
  read: PropTypes.bool,
  onUnreadClick: PropTypes.func,
  createDate: PropTypes.any,
  createAt: PropTypes.any,
  isWhiteMode: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string
}
export default UserTextMessage;

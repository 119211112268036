import * as types from '../actions/types';

const initialState = {
  contactData: {}
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VALIDATE_DEACTIVATE_SUCCEEDED:
      return {
        ...state,
        contactData: action.data
      };
    case types.VALIDATE_DEACTIVATE_FAILED:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default contactReducer;

import * as types from '../actions/types';

const initialState = {
  first: false, // true no sync await
  loggedIn: false,
  data: {},
  isError: false
};

const subscriptionPausedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_PAUSED_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null,
        isError: false
      };
    case types.SUBSCRIPTION_PAUSED_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        data: {},
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null,
        isError: false
      };
    case types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        data: {},
        error: action.error
      };
    case types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null,
        isError: false
      };
    case types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED:
      return {
        ...state,
        isError: true,
        data: {},
        error: action.error
      };
    case types.CONTINUE_SUBSCRIPTION_PAUSED:
      return {
        ...state,
        isError: true,
        error: {
          message: 'CONTINUE_SUBSCRIPTION_PAUSED'
        }
      };
    default:
      return state;
  }
};

export default subscriptionPausedReducer;

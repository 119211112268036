import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';

const INITIAL = {
  first: false, // true no sync await
  loggedIn: false,
  userdata: {},
  isError: false
};

const authReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.SMS_LOGIN_SEND_CODE_REQUESTED:
      return {
        ...state
      };
    case types.SMS_LOGIN_SEND_CODE_SUCCEEDED:
      return {
        ...state
      };
    case types.SMS_LOGIN_SEND_CODE_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        error: action.error
      };
    case types.REACTIVATION_RE_APPROVE_REQUEST:
      return {
        ...state
      };
    case types.REACTIVATION_RE_APPROVE_SUCCEEDED:
      return {
        ...state
      };
    case types.REACTIVATION_RE_APPROVE_FAILED:
      return {
        ...state
      };
    case types.RE_REGISTER_REQUEST:
      return {
        ...state
      };
    case types.RE_REGISTER_SUCCEEDED:
      return {
        ...state
      };
    case types.RE_REGISTER_FAILED:
      return {
        ...state
      };
    case types.SMS_LOGIN_VERIFICATION_CODE_REQUESTED:
      return {
        ...state
      };
    case types.SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED:
      return {
        ...state,
        userdata: action.data.data || {}
      };
    case types.SMS_LOGIN_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        loggedIn: false,
        isError: true,
        error: action.error
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_REQUESTED:
      return {
        ...state
      };

    case types.CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED:
      return {
        ...state,
        userdata: action.data.data || {}
      }
    case types.ADMIN_FORCE_LOGIN_REQUESTED:
      return {
        ...state
      };
    case types.ADMIN_FORCE_LOGIN_SUCCEEDED:
      return {
        ...state,
        userdata: action.data.data || {}
      };
    case types.ADMIN_FORCE_LOGIN_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED:
      return {
        ...state
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED:
      return {
        ...state
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED:
      return {
        ...state
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED:
      return {
        ...state,
        userdata: {},
        loggedIn: false,
        error: null,
        isError: false
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error
      };
    case types.CURRENT_USER_SUCCESS_NOT_401:
      return {
        ...state,
        loggedIn: true
      };
    case types.USER_FORCE_LOGIN_REQUESTED:
      return {
        ...state
      };
    case types.USER_FORCE_LOGIN_SUCCEEDED:
      return {
        ...state,
        userdata: action.data.data || {}
      };
    case types.USER_FORCE_LOGIN_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error
      };
    case types.CLEAR_ACTIVE_SUBSCRIPTION_PAUSE_POPUP:
      return {
        ...state,
        isError: false,
        loggedIn: false,
        userdata: {},
        error: null
      };
    default:
      return state;
  }
});

export default authReducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import { pathEq } from 'ramda';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* rematch(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PARTICIPATION + '/request-participate-rematch',
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (
      pathEq(['status'], 'success', response) ||
      pathEq(['status'], ResponseStatus.SUCCESS, response)
    ) {
      yield put({
        type: types.REMATCH_SUCCEEDED
      });
    } else {
      yield put({
        type: types.REMATCH_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REMATCH_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

export function* watchRematch() {
  yield takeLatest(types.REMATCH_REQUESTED, rematch);
}

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getUserCertification(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_USER_CERTIFICATION,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_CERTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.USER_CERTIFICATION_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USER_CERTIFICATION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* updateUserCertification(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.UPDATE_USER_CERTIFICATION,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_USER_CERTIFICATION_SUCCEEDED
      });
    } else {
      yield put({
        type: types.UPDATE_USER_CERTIFICATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_CERTIFICATION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* storeImageUserCertification(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.STORE_IMAGE_USER_CERTIFICATION,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.STORE_IMAGE_USER_CERTIFICATION_SUCCEEDED
      });
    } else {
      yield put({
        type: types.STORE_IMAGE_USER_CERTIFICATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.STORE_IMAGE_USER_CERTIFICATION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetUserCertification() {
  yield takeLatest(types.USER_CERTIFICATION_REQUESTED, getUserCertification);
}

export function* watchUpdateUserCertification() {
  yield takeLatest(
    types.UPDATE_USER_CERTIFICATION_REQUESTED,
    updateUserCertification
  );
}

export function* watchStoreImageUserCertification() {
  yield takeLatest(
    types.STORE_IMAGE_USER_CERTIFICATION_REQUESTED,
    storeImageUserCertification
  );
}

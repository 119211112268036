import * as types from '../actions/types';

const initialState = {
  isError: false,
  message: [],
  title: ''
};

const globalErrorPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_GLOBAL_ERROR_POPUP:
      return {
        isError: false,
        message: [],
        title: ''
      };
    case types.UPDATE_GLOBAL_ERROR_POPUP:
      return {
        isError: true,
        type: action.action.type,
        message: action.action.message,
        title: action.action.title,
        nextUrl: action.action.nextUrl,
        isSubscriptionPause: action.action.isSubscriptionPause
      };
    case types.CLEAR_ACTIVE_SUBSCRIPTION_PAUSE_POPUP:
      return {
        isError: false,
        message: [],
        title: ''
      };

    default:
      return state;
  }
};

export default globalErrorPopupReducer;

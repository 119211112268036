import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import { ReactComponent as CafeIcon } from '@assets/common/cafe.svg';
import { ReactComponent as VideoIcon } from '@assets/ChatFeature/video-icon.svg';
import { ReactComponent as ClockIcon } from '@assets/common/clock.svg';
import { ReactComponent as CalendarIcon } from '@assets/common/calendar.svg';
import { ReactComponent as CancelIcon } from '@assets/common/cancel.svg';
import { GROUP_ACTION_BUTTON } from '@constants/constant';
import PropTypes from 'prop-types';


class GroupActionButton extends Component {
  handleClick = type => {
    let isCloseButtonGroup = false;
    switch (type) {
      case GROUP_ACTION_BUTTON.CAFE_MESSAGE: {
        this.props.onCafeSuggestionLinkClick();
        break;
      }
      case GROUP_ACTION_BUTTON.VIDEO_MESSAGE: {
        this.props.onVideoSuggestionLinkClick();
        break;
      }
      case GROUP_ACTION_BUTTON.CHANGE_TIME_MESSAGE: {
        this.props.onChangeTimeClick();
        break;
      }
      case GROUP_ACTION_BUTTON.CANCEL_DATING_BY_PARTNER: {
        isCloseButtonGroup = true;
        this.props.transferTicketPopup();
        break;
      }
      case GROUP_ACTION_BUTTON.CANCEL_DATING_POPUP: {
        isCloseButtonGroup = true;
        this.props.openCancelDatingPopup();
        break;
      }
      default:
        break;
    }
    if (!isCloseButtonGroup) {
      this.props.setOpenGroupBtn(false);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className={styles.NewChatActionGroup}>
        <div className={styles.UpperGroup}>
          <div
            className={styles.Item}
            onClick={() => this.handleClick(GROUP_ACTION_BUTTON.CAFE_MESSAGE)}
          >
            <CafeIcon />
            <h3 className={styles.ItemText}>{t('group-action-button-1')}</h3>
          </div>
          <div
            className={styles.Item}
            onClick={() => this.handleClick(GROUP_ACTION_BUTTON.VIDEO_MESSAGE)}
          >
            <VideoIcon />
            <h3 className={styles.ItemText}>{t('group-action-button-2')}</h3>
          </div>
          <div
            className={styles.Item}
            onClick={() =>
              this.handleClick(GROUP_ACTION_BUTTON.CHANGE_TIME_MESSAGE)
            }
          >
            <ClockIcon />
            <h3 className={styles.ItemText}>{t('group-action-button-3')}</h3>
          </div>
        </div>
        <div className={styles.LowerGroup}>
          <div
            className={styles.Item}
            onClick={() =>
              this.handleClick(GROUP_ACTION_BUTTON.CANCEL_DATING_BY_PARTNER)
            }
          >
            <CalendarIcon />
            <h3 className={styles.ItemText}>{t('group-action-button-4')}</h3>
          </div>
          <div className={styles.ItemSeparate}></div>
          <div
            className={styles.Item}
            onClick={() =>
              this.handleClick(GROUP_ACTION_BUTTON.CANCEL_DATING_POPUP)
            }
          >
            <CancelIcon />
            <h3 className={styles.ItemText}>{t('group-action-button-5')}</h3>
          </div>
        </div>
      </div>
    );
  }
}

GroupActionButton.propTypes = {
  setOpenGroupBtn: PropTypes.func,
  onCafeSuggestionLinkClick: PropTypes.func,
  onVideoSuggestionLinkClick: PropTypes.func,
  onChangeTimeClick: PropTypes.func,
  transferTicketPopup: PropTypes.func,
  openCancelDatingPopup: PropTypes.func
}

export default compose(withNamespaces('chat', { wait: true }))(
  GroupActionButton
);

export const getUserLanguage = () => {
  // var userLang = navigator.language || navigator.userLanguage;
  // let languageId = 1;
  // if (
  //   userLang &&
  //   (userLang.indexOf('jp') > -1 || userLang.indexOf('ja') > -1)
  // ) {
  //   languageId = 2;
  // }

  // Always set jp language for now. Reference: https://app.asana.com/0/914850825992482/957033062601540/f
  return 2;
};

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import { ReactComponent as CalendarIcon } from '@assets/common/calendar.svg';
import { ReactComponent as CancelIcon } from '@assets/common/cancel.svg';
import { ReactComponent as UnionIcon } from '@assets/ChatFeature/union.svg';
import { NOTICE_POPUP } from '@constants/constant';
import { renderUpperGroupIcon, textJapanButton } from './utils';
import { handleRenderTextJapan } from '@utils';
import PropTypes from 'prop-types';


class FirstTimeSeeNewDesign extends Component {
  handleChangeShowChatDesign = () => {
    this.props.onPopupNewChatDesignClick();
    this.props.requestChangeShowNewChatDesign({
      notice_popup: NOTICE_POPUP.NEW_CHAT_DESIGN
    });
  };

  render() {
    return this.props.isShowPopupNewChatDesign ? (
      <>
        <div className={styles.PopupNewChatDesign}>
          <div className={styles.GroupAction}>
            <div className={styles.UpperGroup}>
              {renderUpperGroupIcon().map((item, idx) => (
                <div key={idx} className={styles.Item}>
                  {item.icon}
                  <h3 className={styles.ItemText}>
                    {handleRenderTextJapan(item.text, 'chat', textJapanButton)}
                  </h3>
                </div>
              ))}
            </div>
            <div className={styles.LowerGroup}>
              <div className={styles.Item}>
                <CalendarIcon />
                <h3 className={styles.ItemText}>
                  {handleRenderTextJapan(
                    'chat:group-action-button-4',
                    'chat',
                    textJapanButton
                  )}
                </h3>
              </div>
              <div className={styles.ItemSeparate}></div>
              <div className={styles.Item}>
                <CancelIcon />
                <h3 className={styles.ItemText}>
                  {handleRenderTextJapan(
                    'chat:group-action-button-5',
                    'chat',
                    textJapanButton
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className={styles.GroupText}>
            <div className={styles.GroupTextTitle}>
              <UnionIcon className={styles.GroupTextTitleIcon} />
              <h3>
                {handleRenderTextJapan(
                  'chat:group-action-button-6',
                  'chat',
                  textJapanButton
                )}
              </h3>
            </div>
            <h3 className={styles.GroupTextDescription}>
              {handleRenderTextJapan(
                'chat:group-action-button-7',
                'chat',
                textJapanButton
              )}
            </h3>
            <button
              className={styles.GroupTextButton}
              onClick={this.handleChangeShowChatDesign}
            >
              OK
            </button>
          </div>
        </div>
        <div className={styles.PopupNewChatDesignArrow}></div>
        <div className={styles.PopupNewChatDesignOverlay}></div>
      </>
    ) : (
      <></>
    );
  }
}

FirstTimeSeeNewDesign.propTypes = {
  isShowPopupNewChatDesign: PropTypes.bool,
  requestChangeShowNewChatDesign: PropTypes.func,
  onPopupNewChatDesignClick: PropTypes.func
}

export default compose(withNamespaces('chat', { wait: true }))(
  FirstTimeSeeNewDesign
);

import asyncComponent from '@hoc/asyncComponent';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const AsyncStepOne = asyncComponent(() => import('./pages/StepOne/index'));
const AsyncStepTwo = asyncComponent(() => import('./pages/StepTwo/index'));
const AsyncAiScreen = asyncComponent(() => import('./pages/AiScreen/index'));
const AsyncLPScreen = asyncComponent(() => import('./pages/LP/index'));

const FaceTypeRouter = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/step-1`} component={AsyncStepOne} />
      <Route exact path={`${path}/step-2`} component={AsyncStepTwo} />
      <Route exact path={`${path}/ai-screen`} component={AsyncAiScreen} />
      <Route exact path={`${path}/LP`} component={AsyncLPScreen} />
      <Redirect to={`/`} />
    </Switch>
  );
};

export { FaceTypeRouter };

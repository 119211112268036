import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const ButtonComponent = ({ note, onTextLinkClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <div className={styles.note} onClick={onTextLinkClick}>
        {note}
      </div>
    </div>
  );
};

ButtonComponent.propTypes = {
  onTextLinkClick: PropTypes.func,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default ButtonComponent;

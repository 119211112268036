import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const TitleComponent = ({ title1, title2 }) => (
  <div className={styles.titleContainer}>
    <div className={styles.title}>{title1}</div>
    <div className={styles.title}>{title2}</div>
  </div>
);

TitleComponent.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string
}

export default TitleComponent;

import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { any, equals } from 'ramda';
import { LoadingSpinner } from '@components/Loading';

const mapStateToProps = state => {
  return {
    // To show the global loading indicator,
    // just add the reducer's loading state here
    showLoading: any(equals(true))([state.app.isLoading])
  };
};

const LoadingSpinnerContainer = compose(
  connect(mapStateToProps),
  branch(({ showLoading }) => !showLoading, renderNothing)
)(LoadingSpinner);

export { LoadingSpinnerContainer as LoadingSpinner };

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* downloadBannerSaga(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.STORE_USER_CLICKED_BANNER,
      API.POST,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.STORE_BANNER_FUNCTION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.STORE_BANNER_FUNCTION_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.STORE_BANNER_FUNCTION_FAILED, error });
  }
}

export function* watchDownloadBannerSaga() {
  yield takeLatest(types.STORE_BANNER_FUNCTION_REQUEST, downloadBannerSaga);
}

import * as types from '../actions/types';
const INITIAL = {
  data: null,
  isError: false
};

const userRankReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.USER_RANK_INFO_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_RANK_INFO_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null
      };
    case types.USER_RANK_INFO_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default userRankReducer;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import bachelorUser from '@assets/ChatFeature/bachelorUser.png';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class ChangeTimeSuggestionMessage extends Component {
  render() {
    const { t, onClickChangeTimeMessage, isWhiteMode } = this.props;

    return (
      <span className={styles.partnerTextMessage}>
        <div
          className={classNames(styles.chatPartnerTextBubble, {
            [styles.textBlack]: isWhiteMode
          })}
        >
          <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
          <div className={styles.chatTextWrapper}>
            <div className={styles.text}>
              {t('change-time-message-suggestion-1')}
            </div>
            <div className={classNames([styles.text, styles.textBottom])}>
              {t('change-time-message-suggestion-2')}
            </div>
            <div className={styles.text}>
              {t('change-time-message-suggestion-3')}
              <span
                className={styles.chatLinkText}
                onClick={onClickChangeTimeMessage}
              >
                {t('change-time-message-suggestion-4')}
              </span>
            </div>
          </div>
        </div>
        <span className={styles.textNote}>
          {t('message-not-display-to-other-partner')}
        </span>
      </span>
    );
  }
}

ChangeTimeSuggestionMessage.propTypes = {
  onClickChangeTimeMessage: PropTypes.func,
  isWhiteMode: PropTypes.bool
}

export default compose(withNamespaces('chat', { wait: true }))(
  ChangeTimeSuggestionMessage
);

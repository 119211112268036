import asyncComponent from '@hoc/asyncComponent';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const AsyncMyPage = asyncComponent(() => import('./pages/MyPage/index'));


const MyPageRouter = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={AsyncMyPage} />
            <Redirect to={`/`} />
        </Switch>
    );
};

export { MyPageRouter };

import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const MessageComponent = ({ message }) => (
  <div className={styles.messageContainer}>
    <div className={styles.message}>{message}</div>
  </div>
);

MessageComponent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}
export default MessageComponent;

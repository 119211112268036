import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* updateReadStatusForNoticePopup(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_READ_NOTICE_POPUP,
      API.POST,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_READ_STATUS_FOR_NOTICE_POPUP_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.UPDATE_READ_STATUS_FOR_NOTICE_POPUP_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_READ_STATUS_FOR_NOTICE_POPUP_FAILED,
      error
    });
  }
}

function* getNoticePopup(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_NOTICE_POPUP,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_NOTICE_POPUP_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_NOTICE_POPUP_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_NOTICE_POPUP_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchUpdateReadStatusForNoticePopup() {
  yield takeLatest(
    types.UPDATE_READ_STATUS_FOR_NOTICE_POPUP_REQUESTED,
    updateReadStatusForNoticePopup
  );
}

export function* watchGetNoticePopup() {
  yield takeLatest(types.GET_NOTICE_POPUP_REQUESTED, getNoticePopup);
}

import axios from 'axios';
import {
  showMaintain,
  showErrorPopup,
  hideLoading,
  clearPersistRoot
} from '../actions/index';
import * as api from './api';
import { store } from '../store';
import { initializeReducers } from 'initializable-reducer';
import { getUserLanguage } from '../../helper/LanguageHelper';
import { ResponseStatus } from '../../constants/constant';
import { checkActiveSubscriptionPause } from '@utils';

export default function request(options) {
  return axios(options);
}

function* requestAPI(
  path,
  method,
  params = {},
  showGeneralPopup = true,
  timeout = 30000
) {
  //add api want to show global error

  let baseURL = api.API.BASE_URL;
  let additionPath = path;
  let accessToken = yield store.getState().auth.userdata.userAuth
    ? store.getState().auth.userdata.userAuth.token.access_token
    : '';
  //Local
  const lngMap = {
    1: 'en/us',
    2: 'ja/jp'
  };
  const languageId = getUserLanguage();
  let headers = {
    'Content-Type': params.headerContentType || 'application/json',
    'Accept-Language': lngMap[languageId]
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  let response = null;
  try {
    response = yield axios.request({
      url: additionPath,
      method: method,
      baseURL: baseURL,
      headers: headers,
      data: method === api.API.POST ? params : null,
      params: params.query,
      timeout: timeout
    });
    if (response.status === 401) {
      yield store.dispatch(clearPersistRoot());
      yield store.dispatch(initializeReducers());
      yield localStorage.setItem(
        'redirect_to',
        window.location.pathname.startsWith('/login')
          ? ''
          : window.location.pathname + (window.location.search || '')
      );
      // yield redirectFB();
      return response;
      // return response.data;
    } else if (response.status === 20) {
      yield store.dispatch(showMaintain());
      return response;
    }
    return response;
  } catch (e) {
    if (e.response.status === ResponseStatus.DUPLICATE_USER) {
      return e.response.data;
    }
    if (e.response.status === 503) {
      yield store.dispatch(showMaintain());
      return e;
    }
    if (e.response.status === 401) {
      yield store.dispatch(clearPersistRoot());
      yield store.dispatch(initializeReducers());
      yield localStorage.setItem(
        'redirect_to',
        window.location.pathname.startsWith('/login')
          ? ''
          : window.location.pathname + (window.location.search || '')
      );
      if (window.location.pathname !== '/login') {
        window.location.replace('/login');
      }
      if (accessToken) {
        let { message } = e.response.data;
        const action = {
          title: message
        };
        yield store.dispatch(showErrorPopup(action));
      }
    }
    if (checkActiveSubscriptionPause(e.response)) {
      const action = {
        title: e.response.data.errors.title,
        type: e.response.data.errors.type,
        message: e.response.data.errors.messages
      };
      yield store.dispatch(hideLoading());
      yield store.dispatch(showErrorPopup(action));
      return e.response;
    }
    if (
      e.response.status === ResponseStatus.VALIDATEFAIL &&
      !params.notShowGeneralPopupIfValidateFail
    ) {
      const action = {
        title: e.response.data.errors.title
      };
      yield store.dispatch(showErrorPopup(action));
    }
    if (
      e.response.status === ResponseStatus.INTERNAL_SERVER_ERROR &&
      !params.notShowInternalServerError
    ) {
      const action = {
        title: e.response.data.message
      };
      yield store.dispatch(showErrorPopup(action));
    }
    if (
      //case error
      e.response.status > 401 &&
      showGeneralPopup
    ) {
      //message
      const res = e.response.data;
      if (res.errors) {
        let { type, messages, title, nextUrl } = res.errors;
        messages = messages && Object.values(messages);
        const action = {
          type,
          title,
          nextUrl,
          message: messages
        };
        yield store.dispatch(showErrorPopup(action));
      } else {
        let { message } = res;
        const action = {
          title: message
        };
        yield store.dispatch(showErrorPopup(action));
      }
    }
    return e.response;
  }
}

export { requestAPI };

import React from 'react';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import PropTypes from 'prop-types';
//
// Shows text status to the left of the base user's chat bubble.
// Hiển thị trạng thái văn bản ở bên trái của bong bóng trò chuyện của người dùng cơ sở.
// -----------------------
const TextUnread = ({ onUnreadClick, t }) => (
  <div className={styles.unread} onClick={onUnreadClick}>
    {t('chat-message-text-unread')}
  </div>
);
TextUnread.propTypes = {
  onUnreadClick: PropTypes.func
}

export default withNamespaces('chat', { wait: true })(TextUnread);

import * as types from '../actions/types';
const INITIAL = {
  isLoading: false,
  current_server_time: '',
  isNative: false,
  isWhiteMode: true
};

const appReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case types.GET_CURRENT_SERVER_TIME_REQUESTED:
      return {
        ...state
      };
    case types.GET_CURRENT_SERVER_TIME_SUCCEEDED:
      return {
        ...state,
        current_server_time: action.data
      };
    case types.GET_CURRENT_SERVER_TIME_FAILED:
      return {
        ...state,
        current_server_time: ''
      };
    case types.SET_NATIVE_MODE:
      return {
        ...state,
        isNative: action.params
      };
    case types.SET_WHITE_MODE:
      return {
        ...state,
        isWhiteMode: action.params
      };
    default:
      return state;
  }
};

export default appReducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { checkActiveSubscriptionPause } from '@utils';

function* getDatingDayAbleToChat(action) {
  let response = null;
  const showGeneralPopup = false;
  try {
    response = yield call(
      requestAPI,
      `${API.GET_DATING_DAY_ABLE_TO_CHAT}?dayOfWeek=${action.params.datingDayOfWeek}&roomCode=${action.params.roomCode}&datingDayId=${action.params.datingDayId}&datingId=${action.params.datingId}&startTime=${action.params.startTime}`,
      API.GET,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED,
        data: response.data
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({
        type: types.GET_DATING_DAY_ABLE_TO_CHAT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_DATING_DAY_ABLE_TO_CHAT_FAILED, error });
  }
}

export function* watchGetDatingDayAbleToChat() {
  yield takeLatest(
    types.GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED,
    getDatingDayAbleToChat
  );
}

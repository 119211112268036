import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* cancelDate(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.CANCEL_DATE, API.POST, action.params);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_DATE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CANCEL_DATE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_DATE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getCancelDatingOptions(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_CANCEL_DATING_OPTIONS,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CANCEL_DATING_OPTIONS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_CANCEL_DATING_OPTIONS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_CANCEL_DATING_OPTIONS_FAILED,
      error: response
    });
  }
  store.dispatch(hideLoading());
}

export function* watchCancelDate() {
  yield takeLatest(types.CANCEL_DATE_REQUESTED, cancelDate);
}

export function* watchGetCancelDatingOptions() {
  yield takeLatest(
    types.GET_CANCEL_DATING_OPTIONS_REQUESTED,
    getCancelDatingOptions
  );
}

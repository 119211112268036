import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import { Spinner } from '@components/Loading';
import classNames from 'classnames';
import { ReactComponent as SendIcon } from '@assets/ChatFeature/send.svg';
import PropTypes from 'prop-types';
import { isIphone } from '@utils';

// Used for typing messages during chat.
// Được sử dụng để gõ tin nhắn trong khi trò chuyện.
// -----------------------
class ChatTextInput extends Component {
  componentDidUpdate() {
    this.props.setRowOfTextarea(this.props.textAreaRef.current.rowCount);
  }
  state = {
    text: '',
    isFinishMessage: true
  };

  // Will show button send when typing, when remove all will hide button
  onTyping = event => {
    this.setState({
      text: event.target.value
    });
  };

  onSendChatMessage = async () => {
    const { bachelorChatManager, historyClickRef } = this.props;

    // Get the most recent clicked element from the ref
    const historyClick = historyClickRef.current;
    // If it was the textarea, focus it
    if (historyClick === this.props.textAreaRef.current._ref) {
      this.props.textAreaRef.current._ref.focus();
    }
    if (isIphone && !this.state.isFinishMessage) return;
    if (navigator.onLine) {
      if (!bachelorChatManager.isConnected) {
        await bachelorChatManager.connect();
      }
      const text = this.state.text;
      const msgIndex = await bachelorChatManager.sendMessage(text);
      await this.props.saveChatHistory(msgIndex, text);
      this.setState({
        text: ''
      });
    }
  };

  onBlurTextBox = () => {
    setTimeout(() => {
      this.props.setIsTyping(false);
    }, 300);
  };

  onFocusTextBox = () => {
    this.props.setIsTyping(true);
    this.props.setOpenGroupBtn(false);
  };

  render() {
    const { t, chatRoomIsReady, isWhiteMode } = this.props;

    return (
      <div
        className={classNames(styles.chatTextInputWrapper, {
          [styles.chatTextInputFlexEnd]: true
        })}
      >
        <span
          className={classNames(styles.chatTextInput, {
            [styles.chatTextInputWhiteMode]: isWhiteMode,
            [styles.onTyping]: this.state.text.trim().length
          })}
        >
          <Textarea
            value={this.state.text}
            onChange={this.onTyping}
            className={classNames(styles.textInputBox, {
              [styles.textInputBoxWhiteMode]: isWhiteMode,
              [styles.textInputNotTyping]: !this.props.isTyping,
              [styles.displayBlock]: !this.state.text || this.props.isTyping
            })}
            placeholder={t('text-input-placeholder')}
            maxRows={5}
            onFocus={this.onFocusTextBox}
            ref={this.props.textAreaRef}
            onBlur={this.onBlurTextBox}
            onCompositionEnd={() => {
              this.setState({
                isFinishMessage: true
              });
            }}
            onCompositionStart={() => {
              this.setState({
                isFinishMessage: false
              });
            }}
          />
        </span>
        {chatRoomIsReady ? (
          <div
            onClick={this.onSendChatMessage}
            className={classNames(styles.sendButtonText, {
              [styles.sendButtonVisible]: this.state.text.trim().length,
              [styles.atTheBottom]:
                this.props.textAreaRef.current &&
                this.props.textAreaRef.current.rowCount > 1
            })}
            ref={this.props.btnAreaRef}
          >
            <SendIcon
              fill={
                !this.state.isFinishMessage && isIphone ? '#D2D3D5' : 'none'
              }
            />
          </div>
        ) : (
          <Spinner
            hidden={this.state.text.length === 0}
            className={styles.loadingChatContainer}
          />
        )}
      </div>
    );
  }
}

ChatTextInput.propTypes = {
  isWhiteMode: PropTypes.bool,
  chatRoomIsReady: PropTypes.bool,
  bachelorChatManager: PropTypes.any,
  saveChatHistory: PropTypes.func,
  isTyping: PropTypes.bool,
  setIsTyping: PropTypes.func,
  setOpenGroupBtn: PropTypes.func,
  rowOfTextarea: PropTypes.number,
  setRowOfTextarea: PropTypes.func,
  textAreaRef: PropTypes.any
};

export default withNamespaces('chat', { wait: true })(ChatTextInput);

import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const MessageComponent = ({ messages }) => (
  <div className={styles.messageContainer}>
    {messages.map((message, index) => {
      if (index === messages.length - 1) {
        return (
          <>
            <div key={index} className={styles.messageLast}>
              {message.customComponent ? (
                message.customComponent
              ) : (
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              )}
              {message.icon}
            </div>
            {
              message.detailLink &&
              <div className={styles.detailLink}>
                <a
                  href={message.detailLink} 
                  className={styles.link}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  詳細はこちら
                </a>
              </div>
            }
          </>
        );
      }
      return (
        <>
          <div key={index} className={styles.message}>
            {message.customComponent ? (
              message.customComponent
            ) : (
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            )}
            {message.icon}
          </div>
          {
            message.detailLink &&
            <div className={styles.detailLink}>
              <a href={message.detailLink} className={styles.link}>詳細はこちら</a>
            </div>
          }
        </>
      );
    })}
  </div>
);
MessageComponent.propTypes = {
  messages: PropTypes.array
}
export default MessageComponent;

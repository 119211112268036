import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: {}
};

const inviteFriendsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INVITE_FRIENDS_DATA_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.INVITE_FRIENDS_DATA_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        error: null
      };
    case types.INVITE_FRIENDS_DATA_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error,
        //Fake for UI testing
        data: {
          invitation_url: 'https://bulto.page.link/lp',
          invite_female_friend_message:
            'ハイスペック恋活のバチェラーデート！男性の平均年収は1千万以上✨コード入力で特別チケットをプレゼント！🎁コード：5SNYEXThttps://bulto.page.link/lp',
          invite_male_friend_message:
            'ハイスペック恋活のバチェラーデート！コード入力で特別チケットをプレゼント！🎁コード：5SNYEXThttps://bulto.page.link/lp',
          other_link: '',
          other_text: '',
          twitter_link: '#バチェラーデート',
          twitter_text:
            'ハイスペック恋活のバチェラーデート！コード入力で特別チケットをプレゼント！🎁コード：5SNYEXThttps://bulto.page.link/lp',
          user_invitation_code: '5SNYEXT',
          user_status: 4
        }
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default inviteFriendsReducer;

import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: {}
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_FEEDBACK_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.SAVE_USER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.SAVE_USER_FEEDBACK_FAILED:
      return {
        ...state
      };
    case types.USER_FEEDBACK_DETAILS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_FEEDBACK_DETAILS_SUCCEEDED:
      return {
        ...state,
        error: null,
        data: action.data.data || {}
      };
    case types.USER_FEEDBACK_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.CHECK_SEND_FEEDBACK_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CHECK_SEND_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.CHECK_SEND_FEEDBACK_FAILED:
      return {
        ...state,
        error: null,
        data: action.data || {}
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default feedbackReducer;

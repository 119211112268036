import React, { Component } from 'react';
import { TabBar } from 'antd-mobile';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { requestUserGlobalInfo } from '@redux/actions';
import styles from './index.module.css';
import {Colors, REGISTRATION_STEPS, UserGender, UserStatus} from '@constants/constant';
import { path } from 'ramda';
import { isBeforeApprovalUser } from '@utils';
import classNames from 'classnames';
import { ReactComponent as Participation } from '@assets/newSystem/squareItem/svg/participate.svg';
import { ReactComponent as Dating } from '@assets/newSystem/squareItem/svg/matchprofile.svg';
import { ReactComponent as DatingReport } from '@assets/newSystem/squareItem/svg/dating_report.svg';
import { ReactComponent as MyPage } from '@assets/newSystem/squareItem/svg/my_page.svg';

export const BottomTabKeys = {
  PARTICIPATION_TAB: '1',
  MATCH_PROFILE_TAB: '2',
  USER_PROFILE_TAB: '3',
  USER_EVALUATIONS_TAB: '4'
};

export const WhiteListShowTabbar = [
  '/participation-time',
  '/matchprofile',
  '/profile',
  '/evaluation'
];

class BottomTabBar extends Component {
  state = {
    selectedTab: BottomTabKeys.PARTICIPATION_TAB,
    hidden: false,
    userStatus: ''
  };

  componentDidMount() {
    this.checkCurrentPage(window.location);
    this.props.history.listen((location) => {
      this.checkCurrentPage(location);
    });
  }

  checkCurrentPage = location => {
    if (WhiteListShowTabbar.indexOf(location.pathname) > -1) {
      this.setState({ hidden: false });
    } else {
      this.setState({ hidden: true });
    }

    if (window.location.pathname.indexOf('/participation') > -1) {
      this.openTab(BottomTabKeys.PARTICIPATION_TAB);
      return;
    }

    if (window.location.pathname.indexOf('/matchprofile') > -1) {
      this.openTab(BottomTabKeys.MATCH_PROFILE_TAB);
      return;
    }

    if (window.location.pathname.indexOf('/evaluation') > -1) {
      this.openTab(BottomTabKeys.USER_EVALUATIONS_TAB);
      return;
    }

    if (
      window.location.pathname.substring(
        window.location.pathname.indexOf('/') + 1
      ) === 'profile'
    ) {
      this.openTab(BottomTabKeys.USER_PROFILE_TAB);
      return;
    }
  };

  openTab = tab => {
    this.setState({
      selectedTab: tab,
      hidden: false
    });
  };

  onTabSelected = tab => {
    if (tab === BottomTabKeys.PARTICIPATION_TAB) {
      // Navigate to participation page
      this.props.history.push('/participation');
      return;
    }

    if (tab === BottomTabKeys.MATCH_PROFILE_TAB) {
      // Navigate to match profile page
      if (this.props.userGender === UserGender.MALE) {
        this.props.history.push(
          '/matchprofile?has_card=' + this.props.userHasCard
        );
      } else {
        this.props.history.push('/matchprofile');
      }
      return;
    }

    if (tab === BottomTabKeys.USER_PROFILE_TAB) {
      // Navigate to my profile page
      this.props.history.push('/profile');
      return;
    }
    if (tab === BottomTabKeys.USER_EVALUATIONS_TAB) {
      // Navigate to my profile page
      this.props.history.push('/evaluation');
      return;
    }
  };

  render() {
    const { t} = this.props;
    const tabBarPages = [
      '/participation',
      '/evaluation',
      '/matchprofile',
      '/profile'
    ];
    let unselectedColor;
    let selectedColor;
    unselectedColor = Colors.GRAY_BAR;
    selectedColor = Colors.MAIN_ORANGE;
    const tabConfig = {
      Participate: {
        icon: <Participation fill={unselectedColor} />,
        selectedIcon: <Participation fill={selectedColor} />,
        title: t('tab-participation'),
        key: 'Participate',
        selected: this.state.selectedTab === BottomTabKeys.PARTICIPATION_TAB,
        onPress: () => {
          this.setState(
            {
              selectedTab: BottomTabKeys.PARTICIPATION_TAB
            },
            () => {
              this.onTabSelected(BottomTabKeys.PARTICIPATION_TAB);
            }
          );
        }
      },
      ...([UserStatus.APPROVED_BY_SYSTEM, UserStatus.DEACTIVATED].includes(
        this.props.userStatus
      )
        ? {
            Dating: {
              icon: <Dating fill={unselectedColor} />,
              selectedIcon: <Dating fill={selectedColor} />,
              title: t('tab-matchprofile'),
              key: 'Dating',
              dot: this.props.registrationSteps < REGISTRATION_STEPS.StepSixteenth &&
                  this.props.hasParticipation &&
                  this.props.userStatus === UserStatus.APPROVED_BY_SYSTEM,
              selected:
                this.state.selectedTab === BottomTabKeys.MATCH_PROFILE_TAB,
              onPress: () => {
                this.setState(
                  {
                    selectedTab: BottomTabKeys.MATCH_PROFILE_TAB
                  },
                  () => {
                    this.onTabSelected(BottomTabKeys.MATCH_PROFILE_TAB);
                  }
                );
              }
            }
          }
        : {}),

      Evaluations: {
        icon: <DatingReport stroke={unselectedColor} />,
        selectedIcon: <DatingReport stroke={selectedColor} />,
        title: t('tab-evaluation'),
        key: 'Evaluations',
        selected: this.state.selectedTab === BottomTabKeys.USER_EVALUATIONS_TAB,
        onPress: () => {
          this.setState(
            {
              selectedTab: BottomTabKeys.USER_EVALUATIONS_TAB
            },
            () => {
              this.onTabSelected(BottomTabKeys.USER_EVALUATIONS_TAB);
            }
          );
        }
      },
      ...([UserStatus.APPROVED_BY_SYSTEM, UserStatus.DEACTIVATED].includes(
        this.props.userStatus
      )
        ? {
            Mypage: {
              icon: <MyPage fill={unselectedColor} />,
              selectedIcon: <MyPage fill={selectedColor} />,
              title: t('tab-profile'),
              key: 'Mypage',
              selected:
                this.state.selectedTab === BottomTabKeys.USER_PROFILE_TAB,
              onPress: () => {
                this.setState(
                  {
                    selectedTab: BottomTabKeys.USER_PROFILE_TAB
                  },
                  () => {
                    this.onTabSelected(BottomTabKeys.USER_PROFILE_TAB);
                  }
                );
              }
            }
          }
        : {})
    };
    return (
      !isBeforeApprovalUser(this.props.userStatus) &&
      !this.state.hidden && (
        <div
          className={classNames({
            [styles.tabContainer]: true,
            [styles.isIOS]: tabBarPages.includes(window.location.pathname),
          })}
        >
          <TabBar
            unselectedTintColor={Colors.GRAY_BAR}
            tintColor={Colors.MAIN_ORANGE}
            barTintColor={Colors.WHITE}
            hidden={this.state.hidden}
          >
            {Object.keys(tabConfig).map(key => {
              const tabItem = tabConfig[key];
              return (
                <TabBar.Item
                  icon={tabItem.icon}
                  selectedIcon={tabItem.selectedIcon}
                  title={tabItem.title}
                  key={tabItem.key}
                  selected={tabItem.selected}
                  onPress={tabItem.onPress}
                  dot={tabItem.dot}
                />
              );
            })}
          </TabBar>
        </div>
      )
    );
  }
}
const mapStateToProps = state => {
  return {
    userHasCard: state.match.dataMatch.user_has_card,
    userGender: path(['userGlobalInfo', 'data', 'user_gender'], state),
    userStatus: path(['userGlobalInfo', 'data', 'user_status'], state),
    registrationSteps: path(['userGlobalInfo', 'data', 'registration_steps'], state),
    hasParticipation: path(['userGlobalInfo', 'data', 'has_participation'], state),
    registrationStep: path(
      ['userGlobalInfo', 'data', 'registration_steps'],
      state
    ),
    unreadDatingReportNumber: path(
      ['userProfile', 'data', 'unread_dating_report_number'],
      state
    ),
    loggedIn: state.auth.loggedIn,
    isWhiteMode: state.app.isWhiteMode
  };
};

export default compose(
  withNamespaces('bottom-bar', { wait: true }),
  connect(mapStateToProps, {
    requestUserGlobalInfo
  })
)(BottomTabBar);

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';
import { checkActiveSubscriptionPause } from '@utils';

function* getStepOne(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/0',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_ONE_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepOne(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/0/store`,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_FAILED,
        error: response
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_ONE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepTwo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/1',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_TWO_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_TWO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_TWO_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepTwo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/1/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_TWO_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_TWO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_TWO_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepThree(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/2',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_THREE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_THREE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_THREE_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepThree(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/2/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_THREE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_THREE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_THREE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepFour(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/3',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FOUR_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FOUR_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_FOUR_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* postStepFour(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/3/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FOUR_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FOUR_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_FOUR_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/4',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_FIVE_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/4/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_FIVE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepSix(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/5',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SIX_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SIX_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_SIX_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepSix(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/5/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SIX_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SIX_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_SIX_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepSeven(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/6',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FAILED, error });
  }
  store.dispatch(hideLoading());
}
function* postStepSeven(action) {
  let response = null;
  if (!action.params.hideShowLoading) store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/6/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getStepSevenFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/6.5',
      API.GET,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepSevenFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/6.5/store`,
      API.POST,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postStepEight(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/7/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_EIGHT_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_EIGHT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_POST_STEP_EIGHT_FAILED, error });
  }

  store.dispatch(hideLoading());
}

function* getStepOnePointFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/1.5',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepOnePointFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/1.5/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_SUCCEDDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postSpecificStepZero(action) {
  let response = null;
  const showGeneralPopup = false;
  // store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM_SPECIFIC,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_FAILED,
      error
    });
  }
  // store.dispatch(hideLoading());
}

function* getStepUrl(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.REGISTRATION_FORM_GET_STEP_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.REGISTRATION_FORM_GET_STEP_FAILED,
      error
    });
  }
}

//get registration form
export function* watchGetStepUrl() {
  yield takeLatest(types.REGISTRATION_FORM_GET_STEP_REQUESTED, getStepUrl);
}
export function* watchGetStepOne() {
  yield takeLatest(types.REGISTRATION_FORM_GET_STEP_ONE_REQUESTED, getStepOne);
}
export function* watchGetStepTwo() {
  yield takeLatest(types.REGISTRATION_FORM_GET_STEP_TWO_REQUESTED, getStepTwo);
}
export function* watchGetStepThree() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_THREE_REQUESTED,
    getStepThree
  );
}
export function* watchGetStepFour() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED,
    getStepFour
  );
}
export function* watchGetStepFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED,
    getStepFive
  );
}
export function* watchGetStepSix() {
  yield takeLatest(types.REGISTRATION_FORM_GET_STEP_SIX_REQUESTED, getStepSix);
}
export function* watchGetStepSevenFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_REQUESTED,
    getStepSevenFive
  );
}
export function* watchGetStepSeven() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED,
    getStepSeven
  );
}
export function* watchGetStepEight() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED,
    getStepSeven
  );
}
export function* watchGetStepOnePointFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_REQUESTED,
    getStepOnePointFive
  );
}
// post registration form
export function* watchPostStepOne() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_ONE_REQUESTED,
    postStepOne
  );
}

export function* watchPostStepTwo() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_TWO_REQUESTED,
    postStepTwo
  );
}

export function* watchPostStepThree() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_THREE_REQUESTED,
    postStepThree
  );
}

export function* watchPostStepFour() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED,
    postStepFour
  );
}

export function* watchPostStepFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED,
    postStepFive
  );
}
export function* watchPostStepSix() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_SIX_REQUESTED,
    postStepSix
  );
}
export function* watchPostStepSevenFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_REQUESTED,
    postStepSevenFive
  );
}
export function* watchPostStepSeven() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED,
    postStepSeven
  );
}

export function* watchPostStepEight() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED,
    postStepEight
  );
}
export function* watchPostStepOnePointFive() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_REQUESTED,
    postStepOnePointFive
  );
}

export function* watchPostSpecificStepZero() {
  yield takeLatest(
    types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_REQUESTED,
    postSpecificStepZero
  );
}

import React from 'react';
import { ReactComponent as CafeIcon } from '@assets/common/cafe.svg';
import { ReactComponent as VideoIcon } from '@assets/ChatFeature/video-icon.svg';
import { ReactComponent as ClockIcon } from '@assets/common/clock.svg';

export const renderUpperGroupIcon = () => {
  const listIcon = [
    {
      icon: <CafeIcon />,
      text: 'chat:group-action-button-1'
    },
    {
      icon: <VideoIcon />,
      text: 'chat:group-action-button-2'
    },
    {
      icon: <ClockIcon />,
      text: 'chat:group-action-button-3'
    }
  ];

  return listIcon;
};

export const textJapanButton = {
  'chat:group-action-button-1': 'カフェを変更',
  'chat:group-action-button-2': 'ビデオデートに変更',
  'chat:group-action-button-3': '時間変更',
  'chat:group-action-button-4': '相手にキャンセルされた',
  'chat:group-action-button-5': 'デートをキャンセル',
  'chat:group-action-button-6': 'をタップするとメニューが表示されます。',
  'chat:group-action-button-7':
    'カフェを変更、ビデオデートに変更などが行えます。'
};

import React from 'react';
import { Trans } from 'react-i18next';
import styles from './index.module.css';
import { CANCELLATION_CHARGE_LINK } from '@constants/helpLink';
import PropTypes from 'prop-types';


const MessageComponent = ({
  message1,
  message2,
  linkText,
  gender
}) => (
  <div className={styles.messageContainer}>
    <ul>
      <li className={styles.message}>{message1}</li>
      <li className={styles.message}>{message2}</li>
      <Trans
        className={styles.message}
        parent="li"
        values={{ linkText }}
        i18nKey="chat:popup-still-unread-message-3"
      >
        <a
          href={
            gender === 1
              ? CANCELLATION_CHARGE_LINK.MALE
              : CANCELLATION_CHARGE_LINK.FEMALE
          }
          className={styles.textUnderline}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>
      </Trans>
    </ul>
  </div>
);

MessageComponent.propTypes = {
  message1: PropTypes.string,
  message2: PropTypes.string,
  linkText: PropTypes.any,
  gender: PropTypes.number
}

export default MessageComponent;

import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const MessagesComponent = ({ message1, message2 }) => {
  return (
    <div className={styles.messageContainer}>
      <div className={styles.message1}>{message1}</div>
      <div className={styles.message2}>{message2}</div>
    </div>
  );
};
MessagesComponent.propTypes = {
  message1: PropTypes.string,
  message2: PropTypes.string
}
export default MessagesComponent;

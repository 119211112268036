import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const MessagesComponent = ({
  message1,
  message2,
  message3,
  message4,
  message5,
  message6
}) => {
  return (
    <div className={styles.messageContainer}>
      <div className={styles.message1}>{message1}</div>
      <div className={styles.message2}>{message2}</div>
      <div className={styles.message3}>{message3}</div>
      <div className={styles.message4}>{message4}</div>
      <div className={styles.message5}>{message5}</div>
      <div className={styles.message6}>{message6}</div>
    </div>
  );
};
MessagesComponent.propTypes = {
  message1: PropTypes.string,
  message2: PropTypes.string,
  message3: PropTypes.string,
  message4: PropTypes.string,
  message5: PropTypes.string,
  message6: PropTypes.string
}
export default MessagesComponent;

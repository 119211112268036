export const MEETING_CHAT_LINK = {
  MALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/7914531463449',
  FEMALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/7914604011801'
};
export const BEFORE_REMATCHING_TIME_LINK =
  'https://bachelorapp.zendesk.com/hc/ja/articles/4404733325209-%E6%BA%80%E5%93%A1%E5%BE%A1%E7%A4%BC%E3%81%A8%E3%81%AF';
export const AFTER_REMATCHING_TIME_LINK =
  'https://bachelorapp.zendesk.com/hc/ja/articles/4404741252505';
export const USER_PREMIUM_PLAN_FOR_MALE_LINK =
  'https://bulto.page.link/new-female';
export const ABOUT_CHANGE_PLAN_URL =
  'https://bulto.page.link/about-plan-change';
export const DETAIL_ON_DATE_TICKET_LINK =
  'https://bulto.page.link/dating-coupon';
export const MATCHING_MECHANISM_LINK =
  'https://bulto.page.link/about-matching-m';
export const HOW_BATCHER_RATIO_WORKS_LINK =
  'https://bulto.page.link/about-b-rate-m';
export const ZENDESK_REMATCHING_3PM_LINK =
  'https://bulto.page.link/about-b-rate-m';
export const REPORT_WORK_LINK = 'https://bulto.page.link/about-report-m';
export const ZENDESK_BACHELOR_NOT_RENEW_LINK =
  'https://bulto.page.link/about-report-f';
export const ZENDESK_BACHELOR_RATE_LINK =
  'https://bulto.page.link/about-b-rate-f';
export const HREF_EMAIL_RESERVATION = {
  MALE: 'https://bulto.page.link/no-email-m',
  FEMALE: 'https://bulto.page.link/no-email-f'
};
export const CANCELLATION_CHARGE_LINK = {
  MALE: 'https://bulto.page.link/cancellation-fee-m',
  FEMALE: 'https://bulto.page.link/cancellation-fee-f'
};
export const VIDEO_DATE_SUGGESTION = {
  MALE: 'https://bulto.page.link/video-dating-m',
  FEMALE: 'https://bulto.page.link/video-dating-f'
};
export const FEED_BACK_UNSUITABLE_LINK = {
  MALE: 'https://bulto.page.link/point-of-matching-m',
  FEMALE: 'https://bulto.page.link/how-matching-f'
};
export const ABOUT_CREDIT_CARD_REGISTRATION_LINK = {
  MALE: 'https://bulto.page.link/about-card-registration-m',
  FEMALE: 'https://bulto.page.link/about-card-registration-f',
  ODD_MALE: 'https://fdl.bachelorapp.net/Rb6o',
  EVEN_MALE: 'https://fdl.bachelorapp.net/QYhe'
};
export const NORMAL_INBOX_LINK = {
  MALE: 'https://bulto.page.link/gmail-setting-m',
  FEMALE: 'https://bulto.page.link/gmail-setting-f'
};
export const SUCCESSFUL_DATING_LINK = {
  MALE: 'https://bulto.page.link/point-of-matching-m',
  FEMALE: 'https://bulto.page.link/how-matching-f'
};
export const FREQUENTLY_ASKED_QUESTIONS_LINK = {
  MALE: 'https://bulto.page.link/help-m',
  FEMALE: 'https://bulto.page.link/help-f'
};
export const NEW_NEW_FLOW_FEMALE_LINK =
  'https://www.wi.bachelorapp.net/new-new-flow-female';
export const SUPPORT_EMAIL_SETTING_LINK = 'https://bulto.page.link/no-email-m';
export const SUPPORT_BACHELOR_FE_LINK = 'https://bulto.page.link/help-f';
export const SUPPORT_BACHELOR_MA_LINK = 'https://bulto.page.link/help-m';
export const FEMALE_BUY_COUPON_LINK = 'https://r0hlgihg.paperform.co';
export const SUPPORT_SMS_LOGIN_LINK = 'https://bulto.page.link/sms-trouble';
export const FEMALE_RANK_LINK = 'https://www.wi.bachelorapp.net/rank';
export const SUMMER_CAMPAIGN_LINK =  'https://bul.to/bachelor-commit-LP';
export const REQUEST_COMMIT_OPTION_POPUP_LINK = 'http://bul.to/commit-notice';

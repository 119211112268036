import React from 'react';
import styles from './index.module.css';
import className from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ButtonComponent = ({ cafeButtonTitle, note, onCafeButtonClick }) => {
  const isWhiteMode = this.props.isWhiteMode;
  return (
    <div className={styles.buttonContainer}>
      <div
        className={className(styles.button, styles.buttonOk, {
          [styles.buttonWhiteMode]: isWhiteMode
        })}
        onClick={onCafeButtonClick}
      >
        {cafeButtonTitle}
      </div>
      <div className={styles.note}>{note}</div>
    </div>
  );
};

ButtonComponent.propTypes = {
  onCafeButtonClick: PropTypes.func,
  cafeButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

const mapStateToProps = state => {
  return {
    isWhiteMode: state.app.isWhiteMode
  };
};

export default connect(mapStateToProps, {})(ButtonComponent);

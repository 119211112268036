import * as types from '../actions/types';

const initialState = {
  data: null,
  needUpdateNewPreferences: null,
  isError: false,
  isRefresh: false
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_SUCCEEDED:
      return {
        ...state,
        needUpdateNewPreferences: action.data.data,
        error: null
      };
    case types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_FAILED:
      return {
        ...state,
        data: null,
        needUpdateNewPreferences: null,
        isError: true,
        error: action.error
      };
    case types.HIDE_NEED_TO_UPDATE_NEW_PREFERENCES_POPUP:
      return {
        ...state,
        needUpdateNewPreferences: null,
        error: null
      };
    default:
      return state;
  }
};

export default userProfileReducer;

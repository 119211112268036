import * as types from '../actions/types';
import { reject, whereEq } from 'ramda';

const initialState = {
  partnerReflectedByCouponPopupShown: false,
  data: [],
  error: null,
  isError: false
};

const popupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARTNER_REFLECTED_COUPON_POPUP_SHOWN:
      return {
        ...state,
        partnerReflectedByCouponPopupShown: true
      };
    case types.POPUPS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.POPUPS_REQUEST_SUCCEEDED:
      return {
        ...state,
        data: action.data || [],
        error: null
      };
    case types.POPUPS_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error,
        data: []
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.DELETE_POPUP_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.DELETE_POPUP_REQUEST_SUCCEEDED:
      return {
        ...state,
        data: reject(whereEq({ id: action.popup_id }))(state.data),
        error: null
      };
    case types.DELETE_POPUP_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default popupsReducer;

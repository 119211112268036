import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';
import { checkActiveSubscriptionPause } from '@utils';

function* validateDeactivateProfile(action) {
  yield put({ type: types.SHOW_LOADING });
  const response = yield call(
    requestAPI,
    API.VALIDATE_DEACTIVATE_CANCEL_ACCOUNT,
    API.GET
  );

  if (
    action.onComplete &&
    response.status &&
    response.status === ResponseStatus.SUCCESS
  ) {
    yield put({
      type: types.VALIDATE_DEACTIVATE_SUCCEEDED,
      data: response.data.data
    });
    action.onComplete(response.data);
  } else if (response.status === ResponseStatus.FAILURE) {
    yield put({
      type: types.VALIDATE_DEACTIVATE_FAILED,
      error: response.response
    });
  }
  yield put({ type: types.HIDE_LOADING });
}

function* deactiveProfile(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.DEACTIVATE_ACCOUNT,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DEACTIVE_PROFILE_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.DEACTIVE_PROFILE_FAILED,
        error: response.response
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({
        type: types.DEACTIVE_PROFILE_FAILED,
        error: response.response
      });
    }
    if (action.onComplete) {
      action.onComplete(response.status ? response : response.response);
    }
  } catch (error) {
    yield put({ type: types.DEACTIVE_PROFILE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelCommitOption(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.CANCEL_COMMIT_OPTION,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_COMMIT_OPTION_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.CANCEL_COMMIT_OPTION_FAILED,
        error: response.response
      });
    } else {
      yield put({
        type: types.CANCEL_COMMIT_OPTION_FAILED,
        error: response.response
      });
    }
    if (action.onComplete) {
      action.onComplete(response.status ? response : response.response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_COMMIT_OPTION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchValidateDeactivateProfile() {
  yield takeLatest(
    types.VALIDATE_DEACTIVATE_REQUESTED,
    validateDeactivateProfile
  );
}

export function* watchDeactiveProfile() {
  yield takeLatest(types.DEACTIVE_PROFILE_REQUESTED, deactiveProfile);
}

export function* watchCancelCommitOption() {
  yield takeLatest(types.CANCEL_COMMIT_OPTION_REQUEST, cancelCommitOption);
}

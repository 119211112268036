import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import classNames from 'classnames';
import bachelorUser from '@assets/ChatFeature/bachelorUser.png';
import PropTypes from 'prop-types';

class CafeInformationMessage extends Component {
  render() {
    const { t, cafeInfo, isWhiteMode } = this.props;
    const {
      datingDateTime,
      cafeName,
      stationName,
      cafePhone,
      cafeLink,
      hasNoticeBubbleMessage = null
    } = cafeInfo;
    return (
      <span className={styles.partnerTextMessage}>
        <div className={styles.bubbleMessagesGroup}>
          <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
          {hasNoticeBubbleMessage && (
            <div
              className={classNames(styles.chatPartnerTextBubble, 'mb-3', {
                [styles.textBlack]: isWhiteMode
              })}
            >
              <div>
                {t('notice-message-noon-date')}
                <span className={styles.textRedNotice}>
                  {t('notice-message-1-hours-cafe-date')}
                </span>
                <span>{t('notice-message-our-recommend')}</span>
              </div>
              <div>
                <span className={styles.textRedNotice}>
                  {t('notice-message-cafe-rather-than-lunch')}
                </span>
                <span>{t('notice-message-last-message')}</span>
              </div>
            </div>
          )}
          <div
            className={classNames(styles.chatPartnerTextBubble, {
              [styles.textBlack]: isWhiteMode
            })}
          >
            <div className={styles.textContent}>{datingDateTime}</div>
            <div className={styles.textContent}>
              {t('cafe-info-message-line-2')}
              <a href={cafeLink} target={'_blank'} rel="noopener noreferrer" className={styles.textBlack}>
                <u>
                  {cafeName} ({stationName})
                </u>
              </a>
            </div>
            <div className={styles.textContent}>
              {t('cafe-info-message-line-3')}
              <a href={'tel:' + cafePhone} className={styles.textBlack}>
                <u>{cafePhone}</u>
              </a>
            </div>
            <br />
            <div className={styles.textContent}>
              {t('cafe-info-message-line-1-1')}
            </div>
            <div className={styles.textContent}>
              {t('cafe-info-message-line-1-2')}
            </div>
          </div>
        </div>
      </span>
    );
  }
}

CafeInformationMessage.propTypes = {
  isWhiteMode: PropTypes.bool,
  cafeInfo: PropTypes.object
}
export default compose(withNamespaces('chat', { wait: true }))(
  CafeInformationMessage
);

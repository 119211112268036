import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getFaceType(action) {
  let response = null;
  !action.hideLoading && store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_FACE_TYPE_OPTIONS,
      API.GET,
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_FACE_TYPE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_FACE_TYPE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_FACE_TYPE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* updateStepTwoFaceType(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.UPDATE_FACE_TYPE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_FACE_TYPE_SUCCEEDED
      });
    } else {
      yield put({
        type: types.UPDATE_FACE_TYPE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FACE_TYPE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* updateFaceTypeStepOne(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
      response = yield call(
          requestAPI,
          API.API_UPDATE_PROFILE,
          API.POST,
          action.params
      );
      if (response.status === ResponseStatus.SUCCESS) {
          yield put({
              type: types.UPDATE_FACE_TYPE_ONE_SUCCEEDED,
              data: response.data
          });
      } else {
          yield put({
              type: types.UPDATE_FACE_TYPE_ONE_FAILED,
              error: response
          });
      }
      if (action.onComplete) {
          action.onComplete(response);
      }
      
  } catch (error) {
      yield put({
          type: types.UPDATE_FACE_TYPE_ONE_FAILED,
          error
      })
  }
}

export function* watchGetFaceType() {
  yield takeLatest(
    types.GET_FACE_TYPE_REQUESTED,
    getFaceType
  );
}

export function* watchUpdateStepTwoFaceType() {
  yield takeLatest(
    types.UPDATE_FACE_TYPE_REQUESTED,
    updateStepTwoFaceType
  );
}

export function* watchUpdateFaceTypeStepOne() {
  yield takeLatest(
      types.UPDATE_FACE_TYPE_ONE_REQUESTED,
      updateFaceTypeStepOne
  );
}
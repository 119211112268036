import * as types from '../actions/types';

const initialState = {
  isError: false
};

const cancelProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCEL_PROFILE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_PROFILE_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_PROFILE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default cancelProfileReducer;

import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const MessageComponent = ({ messages }) => (
  <div className={styles.messageContainer}>
    <ul>
      {messages.map((message, idx) => (
        <li key={idx} className={styles.message}>{message}</li>
      ))}
    </ul>
  </div>
);

MessageComponent.propTypes = {
  messages: PropTypes.array
}
export default MessageComponent;

import React, { Component } from 'react';
import { store } from '@redux/store';
import { requestSendExceptionNotice } from '@redux/actions';
import { path } from 'ramda';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withTracker from '@hoc/withTracker';
import Popup from '@components/Popup';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    let errMsg = error.message;
    if (this.props.userId) {
      errMsg = error.message + ' with user id = ' + this.props.userId;
    }
    let errorData = {
      message: errMsg + ` at ${window.location.pathname}`,
      stack: info.componentStack
    };
    store.dispatch(requestSendExceptionNotice({ error: errorData }));
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return (
      <>
        <Popup
          visible={hasError}
          title={'エラーが発生しました。\nもう一度お試しください'}
          okButtonTitle={'了解'}
          notShowCloseButton={true}
          onOkClicked={() => {
            this.setState({
              hasError: false
            });
            this.props.history.push('/participation');
          }}
        />
        {!hasError && children}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: path(['auth', 'userdata', 'userAuth', 'user', 'id'], state)
  };
};

export default compose(
  withNamespaces('common', { wait: true }),
  withRouter,
  connect(mapStateToProps, {}),
  withTracker
)(ErrorBoundary);

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';
import { checkActiveSubscriptionPause } from '@utils';

function* getPlan(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PLAN +
        `?costPlan=${action.params.cost_plan}&discountType=${
          action.params.discount_type
        }&contractTerm=${parseInt(action.params.contract_term)}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.GET_PLAN_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.GET_PLAN_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PLAN_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetPlan() {
  yield takeLatest(types.GET_PLAN_REQUESTED, getPlan);
}

function* onChangeUserPlan(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PLAN + `/change/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_PLAN_SUCCEEDED
      });
    } else {
      yield put({
        type: types.UPDATE_PLAN_FAILED
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_PLAN_FAILED
    });
  }
  store.dispatch(hideLoading());
}

export function* watchChangeUserPlan() {
  yield takeLatest(types.UPDATE_PLAN_REQUESTED, onChangeUserPlan);
}

function* validateBeforeChangeUserPlan(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_PLAN + `/validate-before-change`,
      API.GET,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED
      });
    } else {
      if (checkActiveSubscriptionPause(response)) {
        return;
      }
      yield put({
        type: types.VALIDATE_BEFORE_UPDATE_PLAN_FAILED
      });
    }
  } catch (error) {
    yield put({
      type: types.VALIDATE_BEFORE_UPDATE_PLAN_FAILED
    });
  }
}

export function* watchValidateBeforeChangeUserPlan() {
  yield takeLatest(
    types.VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED,
    validateBeforeChangeUserPlan
  );
}

function* getScheduledPlan(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_PLAN + `/get-scheduled-plan`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_SCHEDULED_PLAN_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_SCHEDULED_PLAN_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_SCHEDULED_PLAN_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetScheduledPlan() {
  yield takeLatest(types.GET_SCHEDULED_PLAN_REQUESTED, getScheduledPlan);
}

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* subscriptionPausedRequest(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_SUBSCRIPTION_PAUSED_REQUEST,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.SUBSCRIPTION_PAUSED_REQUEST_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.SUBSCRIPTION_PAUSED_REQUEST_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_PAUSED_REQUEST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelSubscriptionPauseRequest(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_CANCEL_SUBSCRIPTION_PAUSED_REQUEST,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getSubscriptionPauseInfoForCancel(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL,
      API.GET
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_SUCCEEDED,
        data: response.data.data
      });
      action.onComplete(response.data.data);
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({
      type: types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* continueSubscriptionPause() {
  store.dispatch(showLoading());
  yield put({
    type: types.CONTINUE_SUBSCRIPTION_PAUSED
  });
  store.dispatch(hideLoading());
}

export function* watchSubscriptionPausedRequest() {
  yield takeLatest(
    types.SUBSCRIPTION_PAUSED_REQUEST,
    subscriptionPausedRequest
  );
}

export function* watchCancelSubscriptionPauseRequest() {
  yield takeLatest(
    types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST,
    cancelSubscriptionPauseRequest
  );
}

export function* watchGetSubscriptionPauseInfoForCancel() {
  yield takeLatest(
    types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_REQUESTED,
    getSubscriptionPauseInfoForCancel
  );
}

export function* watchContinueSubscriptionPause() {
  yield takeLatest(
    types.CONTINUE_SUBSCRIPTION_PAUSED,
    continueSubscriptionPause
  );
}
